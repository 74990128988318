import React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import LineChart from './charts/Chartjs/LineChart';
import BarChart from './charts/Chartjs/BarChart';
import DoughnutChart from './charts/Chartjs/DoughnutChart';
import PieChart from './charts/Chartjs/PieChart';
import RadarChart from './charts/Chartjs/RadarChart';
import PolarChart from './charts/Chartjs/PolarChart';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';

export const Dashboard = () => {
  const Divider = styled(MuiDivider)(spacing);
  const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        Charts
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Charts</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <LineChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <BarChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <DoughnutChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <RadarChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolarChart />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
