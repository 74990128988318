import * as APITypes from '../../types/api/Audience';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCbaAudiences = /* GraphQL */ `query ListCbaAudiences(
  $filter: FilterInput
) {
  listCbaAudiences(
    filter: $filter
  ) {
    items {
         affiliate_id
         audience
         advertiser_id
         channel
    }
  }
}
` as GeneratedQuery<
  APITypes.AudienceQueryVariables,
  APITypes.ListAudienceQuery
>;
