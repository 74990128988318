import * as APITypes from '../../types/api/Offer';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCbaOffers = /* GraphQL */ `query ListCbaOffers(
  $filter: FilterInput
) {
  listCbaOffers(
    filter: $filter
  ) {
    items {
         offer_id
         offer_name
    }
  }
}
` as GeneratedQuery<APITypes.OfferQueryVariables, APITypes.ListOfferQuery>;
