import { CbaPageDestination } from '../../../types/api/PageDestination';
import {
  TemplateField,
  TrackingTemplate,
} from '../../../types/api/LinkTrackingTemplates';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { Affiliate } from '../../../types/api/Affiliate';

export const generateStaticRotatorParams = (
  pageDestination: CbaPageDestination,
  affiliate: Affiliate,
) => {
  return pageDestination.url_type === 'Page in Rotator'
    ? `/aff_ad?campaign_id=${pageDestination.rotator_id}&aff_id=${affiliate.affiliate_id}`
    : `/aff_c?offer_id=${pageDestination.offer_id}&url_id=${pageDestination.url_id}&aff_id=${affiliate.affiliate_id}`;
};

const generateManualParam = (
  templateField: TemplateField,
  linkForm: RotatorLinkForm,
) => {
  if (!linkForm[templateField.url_param_key]) {
    return undefined;
  }
  return `${templateField.url_param_key}=${
    linkForm[templateField.url_param_key]
  }`;
};

export const generateDynamicParam = (templateField: TemplateField) => {
  return `${templateField.url_param_key}=${templateField.value}`;
};

export const generateLink = (
  linkForm: RotatorLinkForm,
  trackingTemplate?: TrackingTemplate | null,
) => {
  const pageDestination = linkForm.pageDestination || {};
  const affiliate = linkForm.affiliate || {};

  const baseLink = `https://${
    pageDestination.tracking_url_hostname
  }${generateStaticRotatorParams(pageDestination, affiliate)}`;

  if (!trackingTemplate) {
    return baseLink;
  }

  const fieldsParams: Array<string | undefined> = Object.values(
    trackingTemplate,
  )
    .sort((temp1, temp2) => temp1.order_index - temp2.order_index)
    .map((templateField) => {
      const isDynamic = templateField.param_type === 'dynamic';
      const isStatic = templateField.param_type === 'static';

      if (!isDynamic && !isStatic) {
        return generateManualParam(templateField, linkForm);
      }

      return generateDynamicParam(templateField);
    })
    .filter((templateField) => !!templateField);

  return `${baseLink}${
    fieldsParams?.length > 0 ? '&' + fieldsParams.join('&') : ''
  }`;
};
