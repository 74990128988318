import * as APITypes from '../../types/api/GroupPermission';

type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createGroupPermissions =
  /* GraphQL */ `mutation CreateGroupPermissions(
  $input: CreateGroupPermissionsInput!
) {
  createGroupPermissions(input: $input) {
    id
    group_name 
    allowed_advertiser
  }
}
` as GeneratedMutation<
    APITypes.CreateGroupPermissionsMutationVariables,
    APITypes.CreateGroupPermissionMutation
  >;

export const updateGroupPermissions =
  /* GraphQL */ `mutation UpdateGroupPermissions(
  $input: UpdateGroupPermissionsInput!
) {
  updateGroupPermissions(input: $input) {
    id
    group_name 
    allowed_advertiser
  }
}
` as GeneratedMutation<
    APITypes.UpdateGroupPermissionsMutationVariables,
    APITypes.CreateGroupPermissionMutation
  >;

export const deleteGroupPermissions =
  /* GraphQL */ `mutation DeleteGroupPermissions(
  $input: DeleteGroupPermissionsInput!
) {
  deleteGroupPermissions(input: $input) {
    id
    group_name 
    allowed_advertiser
  }
}
` as GeneratedMutation<
    APITypes.DeleteGroupPermissionsMutationVariables,
    APITypes.CreateGroupPermissionMutation
  >;
