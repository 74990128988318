import { Box, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  link: string;
}
export default function LinkItem({ link }: Props) {
  return (
    <Box display="flex" gap={2}>
      <ContentCopyIcon
        sx={{
          cursor: 'pointer',
          '&:hover': {
            color: 'primary.main',
          },
        }}
        onClick={() => {
          navigator.clipboard.writeText(link);
        }}
      />
      <Typography sx={{ mb: 3 }}>{link}</Typography>
    </Box>
  );
}
