import * as React from 'react';
import styled from '@emotion/styled';
import { Power } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { RouterPath } from '../../utils/routerUtils';
import { linksApi } from '../../store/links';
import { adminApi } from '../../store/admin';
import { useDispatch } from 'react-redux';
import { rotatorsApi } from '../../store/rotators';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const dispatch = useDispatch();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const toProfile = () => {
    closeMenu();
    navigate(RouterPath.PROFILE);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
    dispatch(linksApi.util.resetApiState());
    dispatch(rotatorsApi.util.resetApiState());
    dispatch(adminApi.util.resetApiState());
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large">
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}>
        <MenuItem onClick={toProfile}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
