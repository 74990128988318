import { Box } from '@mui/material';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import { dictionary } from '../../../utils/dictionary';
import VirtualizedAutocomplete from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';
import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { useGetAdvertisersListQuery } from '../../../store/links';
import {
  convertAdvertisersToOptions,
  convertAdvertiserToOptionValue,
} from './utils';

export default function AdvertiserStep() {
  const { values, setFieldValue, errors, touched, setTouched } =
    useFormikContext<RotatorLinkForm>();

  const { data: advertisers, isLoading } = useGetAdvertisersListQuery();

  useEffect(() => {
    if (advertisers?.length === 1) {
      setFieldValue(
        'advertiser',
        convertAdvertiserToOptionValue(advertisers[0]),
      );
    }
  }, [advertisers, setFieldValue]);

  const options = useMemo(
    () => convertAdvertisersToOptions(advertisers),
    [advertisers],
  );

  const selectedValue =
    options.find(
      (option) =>
        option.value.advertiser_id === values.advertiser?.advertiser_id,
    ) || null;

  return (
    <Box>
      <PageSubHeader text={dictionary.links.newLink.advertiserStepHeader} />
      <VirtualizedAutocomplete
        // @ts-ignore
        onBlur={() => setTouched({ ...touched, advertiser: true })}
        required
        loading={isLoading}
        options={options}
        label={dictionary.links.newLink.advertiserSelectLabel}
        onChange={(event, value) => {
          setFieldValue('advertiser', value?.value);
        }}
        value={selectedValue}
        error={touched.advertiser && (errors.advertiser as string)}
      />
    </Box>
  );
}
