import {MutableRefObject, useState} from "react";
import {GridApiPremium} from "@mui/x-data-grid-premium/models/gridApiPremium";

interface Props {
    apiRef: MutableRefObject<GridApiPremium>
    storageKey: string
}

export const useSaveTableView = ({ apiRef, storageKey }: Props) => {

    const [ isViewChanged, setIsViewChanged ] = useState(false);

    const handleSaveView = () => {
        setIsViewChanged(false);
        const { columns, sorting } = apiRef.current.state;
        const serializedColumns = JSON.stringify({columns, sortModel: sorting.sortModel});
        localStorage.setItem(storageKey, serializedColumns);
    }

    const handleViewChange = () => {
        setIsViewChanged(true);
    }

    return { isViewChanged, handleViewChange, handleSaveView };

}
