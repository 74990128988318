import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { getBreadcrumbs } from './utils';

export const StyledBreadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Breadcrumbs = () => {
  const location = useLocation();

  return (
    <Box sx={{ mb: 2 }} aria-label="Breadcrumb">
      <StyledBreadcrumbs>
        {getBreadcrumbs(location.pathname)?.props.children}
      </StyledBreadcrumbs>
    </Box>
  );
};
