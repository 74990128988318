import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  getCbaAppRotatorContents,
  listCbaAppRotatorContents,
} from '../graphql/rotators/queries';
import { generateClient } from 'aws-amplify/api';
import { CbaAppRotatorContents } from '../types/api/Rotators';
import { filterOutNull } from '../utils/arrayUtils';
import { updateCbaAppRotatorContents } from '../graphql/rotators/mutations';

const client = generateClient();

export const rotatorsApi = createApi({
  reducerPath: 'rotatorsApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/graphql' }),
  endpoints: (builder) => {
    return {
      getRotatorsList: builder.query<CbaAppRotatorContents[], void>({
        async queryFn() {
          let data: CbaAppRotatorContents[] = [];
          try {
            const result = await client.graphql({
              query: listCbaAppRotatorContents,
            });

            const items = result.data?.listCbaAppRotatorContents?.items || [];

            if (items != null) {
              const filteredItems = filterOutNull<CbaAppRotatorContents>(items);
              data = [...data, ...filteredItems];
            }
            return { data };
          } catch (error: any) {
            console.error(error);

            if (!!error.data) {
              const items = error.data?.listCbaAppRotatorContents?.items;
              if (items != null) {
                data = [...data, ...items];
              }
            }
            return { error, data };
          }
        },
      }),
      getRotator: builder.query<
        CbaAppRotatorContents,
        { rotator_id: number; rotator_content_id: number }
      >({
        async queryFn({ rotator_id, rotator_content_id }) {
          try {
            const result = await client.graphql({
              query: getCbaAppRotatorContents,
              variables: { rotator_id, rotator_content_id },
            });
            return { data: result.data?.getCbaAppRotatorContents };
          } catch (error: any) {
            return { error, data: error.data?.getCbaAppRotatorContents } as any;
          }
        },
      }),
      updateRotator: builder.mutation<
        CbaAppRotatorContents,
        Pick<
          CbaAppRotatorContents,
          | 'rotator_id'
          | 'rotator_content_id'
          | 'is_control'
          | 'rotator_content_custom_weight'
        >
      >({
        async queryFn(rotator) {
          try {
            const result = await client.graphql({
              query: updateCbaAppRotatorContents,
              variables: { input: { ...rotator } },
            });
            return { data: result.data };
          } catch (error: any) {
            return { error, data: error.data?.getCbaAppRotatorContents } as any;
          }
        },
      }),
    };
  },
});

export const {
  useGetRotatorsListQuery,
  useGetRotatorQuery,
  useUpdateRotatorMutation,
} = rotatorsApi;
