import React from 'react'
import {
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent,
    Divider as MuiDivider,
    Grid,
    Link,
    Typography
} from "@mui/material";
import {NavLink} from "react-router-dom";
import styled from "@emotion/styled";
import {spacing} from "@mui/system";
import {SimpleCard} from "../components/SimpleCard";
import ruby from './../vendor/ruby.png';
import store from './../vendor/store.svg';
import funnel from './../vendor/funnel.png';

export const Tools = () => {
    const Divider = styled(MuiDivider)(spacing);
    const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
    const Card = styled(MuiCard)(spacing);

    const Spacer = styled.div(spacing);

    return (<>
        <React.Fragment>
            <Typography variant="h3" gutterBottom display="inline">
                Tools
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Typography>Charts</Typography>
            </Breadcrumbs>

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <Card mb={1}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Funnels
                            </Typography>
                            <Spacer mb={6}/>
                            <SimpleCard
                                imgSrc={funnel}
                                title={"Funnels"}
                                redirectLink={"funnel"}
                            />
                        </CardContent>
                    </Card> </Grid>
                <Grid item xs={12} md={6}>
                    <Card mb={1}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Queue
                            </Typography>
                            <Spacer mb={6}/>
                            <SimpleCard
                                imgSrc={ruby}
                                title={"Queue"}
                                redirectLink={"queue"}
                            />
                        </CardContent>
                    </Card> </Grid>
                <Grid item xs={12} md={6}>
                    <Card mb={1}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Stores
                            </Typography>
                            <Spacer mb={6}/>
                            <SimpleCard
                                imgSrc={store}
                                title={"Stores"}
                                redirectLink={"stores"}
                            />
                        </CardContent>
                    </Card> </Grid>

            </Grid>
        </React.Fragment>
    </>)

}
