import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { useGetAudiencesListQuery } from '../../../store/links';
import VirtualizedAutocomplete from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';
import React, { useMemo } from 'react';
import { TemplateField } from '../../../types/api/LinkTrackingTemplates';
import { getSelectedValue } from './utils';

interface Props {
  field: TemplateField;
}
export default function SourceParamAutocomplete({ field }: Props) {
  const { values, setFieldValue } = useFormikContext<RotatorLinkForm>();

  const { data: audiences, isLoading } = useGetAudiencesListQuery({
    filter: {
      items: [
        {
          field: 'channel',
          operator: 'equals',
          value: values.affiliate.channel,
        },
        {
          field: 'advertiser_id',
          operator: '=',
          value: `${values.advertiser.advertiser_id}`,
        },
      ],
    },
  });

  const options = useMemo(() => {
    return (
      audiences
        ?.map((audience) => ({
          value: audience.audience,
          label: audience.audience,
          id: audience.audience,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }, [audiences]);

  const selectedValue =
    getSelectedValue(options, values[field.url_param_key] as string) || null;

  return (
    <VirtualizedAutocomplete
      loading={isLoading}
      freeSolo
      options={options}
      label={field.name}
      onChange={(event, value) =>
        setFieldValue(field.url_param_key, value?.value || value)
      }
      value={selectedValue}
      helperText={field.description}
    />
  );
}
