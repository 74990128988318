import { object, string } from 'yup';

export const RotatorLinkSchema = object().shape({
  advertiser: object().when('step', (step, schema) => {
    if (step[0] === 0) {
      return schema.required('Advertiser is required');
    }
    return schema;
  }),
  pageDestination: object().when('step', (step, schema) => {
    if (step[0] === 2) {
      return schema.required('Page destination is required');
    }
    return schema;
  }),
  affiliate: object().when('step', (step, schema) => {
    if (step[0] === 1) {
      return schema.required('Traffic source is required');
    }
    return schema;
  }),
  source: string().max(50, 'Too Long!'),
  affiliate_unique3: string().max(50, 'Too Long!'),
  affiliate_unique4: string().max(50, 'Too Long!'),
  affiliate_unique5: string().max(50, 'Too Long!'),
});
