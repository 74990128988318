import { Tenant, TenantForm } from '../../../types/api/Tenant';
import { Box, Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { AdminSchema } from '../../../utils/validation/AdminSchema';
import { dictionary } from '../../../utils/dictionary';
import { RouterPath } from '../../../utils/routerUtils';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  useCreateTenantUserMutation,
  useUpdateTenantUserMutation,
} from '../../../store/admin';
import { TYPE_USER_ROLES } from '../../../utils/auth';
import { convertTenantToTenantForm } from '../CreateUpdateTenantForm/utils';

interface Props {
  admin: Tenant;
  userRole: TYPE_USER_ROLES;
}
export default function CreateUpdateUserForm({ admin, userRole }: Props) {
  const [createUser, { isLoading }] = useCreateTenantUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] =
    useUpdateTenantUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (values: TenantForm) => {
    const response = admin
      ? await updateUser({
          userId: admin.sortKey,
          user: values,
          userRole: userRole,
        }).unwrap()
      : await createUser({
          user: values,
          userRole: userRole,
        }).unwrap();

    if (typeof response !== 'string' && 'error' in response) {
      enqueueSnackbar(dictionary.actions.serverError, {
        variant: 'error',
      });
    } else {
      const successMessage = !!admin
        ? dictionary.admin.updateUserSuccess
        : dictionary.admin.createUserSuccess;
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      navigate(RouterPath.ADMIN);
    }
  };

  return (
    <Box pt={4} width="70%">
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={AdminSchema}
        initialValues={convertTenantToTenantForm(admin)}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleBlur,
          values,
          handleChange,
          setFieldValue,
          errors,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={6}>
              <TextField
                label="First name"
                name="firstName"
                onChange={handleChange}
                value={values['firstName']}
                onBlur={handleBlur}
                error={!!errors['firstName']}
                helperText={errors['firstName']}
              />
              <TextField
                label="Last name"
                name="lastName"
                onChange={handleChange}
                value={values['lastName']}
                onBlur={handleBlur}
                error={!!errors['lastName']}
                helperText={errors['lastName']}
              />
              <TextField
                disabled={!!admin}
                label="Email"
                name="email"
                onChange={handleChange}
                value={values['email']}
                onBlur={handleBlur}
                error={!!errors['email']}
                helperText={errors['email']}
              />
              <MuiTelInput
                placeholder="Type your phone"
                label="Phone"
                value={values.phone}
                onChange={(phoneValue) => {
                  setFieldValue('phone', phoneValue);
                }}
              />
              <Button
                disabled={!dirty || isLoading || isLoadingUpdate}
                type="submit"
                variant="contained"
                sx={{ width: 'fit-content', alignSelf: 'flex-end' }}>
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}
