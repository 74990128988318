import { useEffect, useState } from 'react';
import { useGetTenantUserQuery } from '../store/admin';
import { getTenantId, USER_ROLES } from '../utils/auth';

interface Props {
  skip?: boolean;
}

export const useTenantAdmin = ({ skip }: Props) => {
  const [tenantId, setTenantId] = useState<string | undefined>();

  const { data: tenantAdmin } = useGetTenantUserQuery(
    {
      userRole: USER_ROLES.TENANT_ADMIN,
      userId: tenantId!,
    },
    {
      skip: !tenantId || skip,
    },
  );

  useEffect(() => {
    async function fetchTenantId() {
      const userTenantId = await getTenantId();
      setTenantId(userTenantId);
    }
    fetchTenantId();
  }, []);

  return { tenantAdmin };
};
