import styled from '@emotion/styled';
import { Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';

export const drawerWidth = 258;
export const drawerTransition = 'width 0.3s ease, opacity 0.3s ease';

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

type DrawerProps = {
  open: boolean;
};

export const Drawer = styled.div`
  width: ${(props: DrawerProps) => (props.open ? `${drawerWidth}px` : 0)};
  transition: ${drawerTransition};
`;

type AppContentProps = {
  isDrawerOpen: boolean;
  isMobile: boolean;
};

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: ${(props: AppContentProps) =>
    props.isDrawerOpen && !props.isMobile ? 'calc(100% - 258px)' : '100%'};
  transition: width 3s ease, opacity 3s ease;
`;

const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  height: calc(100vh - 125px);

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;
