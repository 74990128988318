import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  useGridApiContext,
  useGridApiEventHandler,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridRow, GridRowId } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import * as React from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  customSumAggregationFunc,
  getInitialState,
  getRotatorsSubTableColumns,
  SUB_TABLE_VIEW_STORAGE_KEY,
} from './utils';
import CustomGridToolbar from '../CustomGridToolbar/CustomGridToolbar';
import { useSaveTableView } from '../../../hooks/useSaveTableView';
import { useUpdateRotatorMutation } from '../../../store/rotators';

const DEFAULT_ROW_HEIGHT = 52;

export const RotatorsSubTable = memo(SubTable, (oldProps, newProps) => {
  return (
    oldProps.rows === newProps.rows &&
    oldProps.renderedColumns === newProps.renderedColumns
  );
});

function SubTable(props: any) {
  const globalContext = useGridApiContext();
  const apiRef = useGridApiRef();
  const state = globalContext?.current.state;
  const tree = state.rows.tree;
  const row = tree && tree[props.rowId];
  const [rows, setRows] = useState<Array<GridRowModel>>([]);
  const [editedRows, setEditedRows] = useState<Record<number, GridRowModel>>(
    [],
  );
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [validationError, setValidationError] = useState<string | undefined>();
  const [isExpanded, setIsExpanded] = useState(false);

  const [updateRotator] = useUpdateRotatorMutation();

  const { isViewChanged, handleViewChange, handleSaveView } = useSaveTableView({
    apiRef,
    storageKey: SUB_TABLE_VIEW_STORAGE_KEY,
  });

  let children: GridRowId[] = [];
  if ('children' in row) {
    children = row?.children;
  }

  const handleEvent: GridEventListener<'rowExpansionChange'> = (params) => {
    if (row.id === params.id && params.childrenExpanded !== isExpanded) {
      setIsExpanded(params.childrenExpanded || false);
    }
  };

  globalContext.current.subscribeEvent('rowExpansionChange', handleEvent);

  useGridApiEventHandler(globalContext, 'rowExpansionChange', handleEvent);

  useEffect(() => {
    let children: GridRowId[] = [];
    if ('children' in row) {
      children = row?.children;
    }

    const filteredRows: Array<GridRowModel> = props.rows
      .filter((row: { rotator_content_id: GridRowId }) => {
        return children?.includes(row.rotator_content_id);
      })
      .map((row: GridRowModel) => {
        const editedRow = editedRows[row.rotator_content_id];
        return editedRow || row;
      });

    setRows(filteredRows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const closeEditMode = (ignoreModifications: boolean) => {
    setRowModesModel((prevRowModel) => {
      const nextState = prevRowModel;
      Object.keys(prevRowModel).forEach((key) => {
        nextState[key] = {
          mode: GridRowModes.View,
          ignoreModifications: ignoreModifications,
        };
      });
      return { ...prevRowModel, ...nextState };
    });
  };

  const handleCancelClick = () => () => {
    closeEditMode(true);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = () => () => {
    const updatedRows = rows.map((row) =>
      apiRef.current.getRowWithUpdatedValues(
        row.rotator_content_id,
        'rotator_content_custom_weight',
      ),
    );

    const totalWeight = updatedRows.reduce(
      (acc, item) => acc + item.rotator_content_custom_weight,
      0,
    );

    if (totalWeight !== 100) {
      setValidationError('The total weight should be 100%');
    } else {
      setValidationError(undefined);
      closeEditMode(false);
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (validationError) {
      return;
    }

    const updatedRow = {
      rotator_content_id: newRow.rotator_content_id,
      rotator_id: newRow.rotator_id,
      is_control: newRow.is_control,
      rotator_content_custom_weight: newRow.rotator_content_custom_weight,
    };

    await updateRotator(updatedRow);

    setEditedRows((prevState) => ({
      ...prevState,
      [newRow.rotator_content_id]: newRow,
    }));

    return newRow;
  };

  const rotatorsColumns = useMemo(
    () =>
      getRotatorsSubTableColumns({
        rowModesModel,
        handleCancelClick,
        handleEditClick,
        handleSaveClick,
        validationError,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowModesModel, validationError],
  );

  if (!children || children?.length === 0) {
    return null;
  }

  if (!isExpanded) {
    return <GridRow {...props} />;
  }

  const height = DEFAULT_ROW_HEIGHT * (rows.length + 4.4);

  return (
    <>
      <GridRow {...props} />
      <Box sx={{ height: height, width: '96%', marginLeft: 10 }}>
        <DataGridPremium
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            sum: customSumAggregationFunc,
          }}
          onColumnVisibilityModelChange={handleViewChange}
          onColumnResize={handleViewChange}
          onColumnOrderChange={handleViewChange}
          onSortModelChange={handleViewChange}
          apiRef={apiRef}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          editMode="row"
          onRowModesModelChange={handleRowModesModelChange}
          initialState={getInitialState()}
          rows={rows}
          columns={rotatorsColumns}
          getRowId={(row: any) => row?.rotator_content_id}
          slots={{ toolbar: CustomGridToolbar }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              excelOptions: { disableToolbarButton: true },
              isViewChanged: isViewChanged,
              onSaveView: handleSaveView,
            },
          }}
          disableColumnFilter
          disableDensitySelector
          getAggregationPosition={() => 'footer'}
          disableRowSelectionOnClick
          sx={{
            border: 'none',
            '& .MuiDataGrid-pinnedColumnHeaders': {
              paddingRight: '0 !important',
            },
          }}
        />
      </Box>
    </>
  );
}
