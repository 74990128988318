import {
  DEFAULT_TENANT_FORM,
  Tenant,
  TenantForm,
} from '../../../types/api/Tenant';

export const convertTenantToTenantForm = (tenant?: Tenant): TenantForm => {
  if (!tenant) {
    return DEFAULT_TENANT_FORM;
  }
  return {
    firstName: tenant.firstName,
    lastName: tenant.lastName,
    email: tenant.email,
    phone: tenant.phone,
    adminAccess: tenant.adminAccess,
    readAccess: tenant.readAccess,
    writeAccess: tenant.writeAccess,
  };
};
