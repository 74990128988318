import { object, string } from 'yup';

export const AdminSchema = object().shape({
  firstName: string().max(50, 'Too Long!').required('First name is required'),
  lastName: string().max(50, 'Too Long!').required('Last name is required'),
  email: string()
    .email('Invalid email')
    .max(50, 'Too Long!')
    .required('Email is required'),
  phone: string().max(50, 'Too Long!'),
});
