import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Dialog,
} from '@mui/material';
import { spacing } from '@mui/system';
import { Add as AddIcon } from '@mui/icons-material';

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface tFunnelDataRows {
  id: number;
  title: string;
  subtitle: string;
  numOfProducts: number;
  img?: string;
}

interface DataGridProps {
  tableTitle: string;
  data: Array<tFunnelDataRows>;
}

function FunnelTable(props: DataGridProps) {
  const columns: Array<string> = [
    'ID',
    'Title',
    'Number of Products',
    'Actions',
  ];

  const { data } = props;
  return (
    <Card mb={6}>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((colTitle) => (
                <TableCell>{colTitle}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title}
                  <Typography>{row.subtitle}</Typography>
                </TableCell>
                <TableCell>
                  {row.numOfProducts}
                  <Typography>Products</Typography>
                </TableCell>
                <TableCell>
                  <Button>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

//TODO(mwalker): Tie in real data.
//TODO(mwalker): Fix rows per page.
export const Funnels = () => {
  const [open, setOpen] = useState(false);

  const dummyData = [
    {
      id: 1,
      title: 'dadswholiftofficial.myshopify.com',
      subtitle: 'dadswholift',
      numOfProducts: 102,
    },
    {
      id: 2,
      title: 'StoneForceMedia~megadrexp',
      subtitle: 'dadswholift',
      numOfProducts: 102,
    },
    {
      id: 3,
      title: 'StoneForceMedia~tachomedef',
      subtitle: 'dadswholift',
      numOfProducts: 102,
    },
    {
      id: 4,
      title: 'myshopify.com',
      subtitle: 'dadswholift',
      numOfProducts: 102,
    },
    {
      id: 5,
      title: 'party.myshopify.com',
      subtitle: 'dadswholift',
      numOfProducts: 102,
    },
  ];

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth={true}>
        <DialogTitle id="form-dialog-title">Create New Funnel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose a name for this Funnel
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
          />

          <DialogContentText>
            Please Supply Clickfunnels Funnel Id
          </DialogContentText>
          <TextField
            margin="dense"
            id="clickFunnelId"
            label="ClickFunnelID"
            type="number"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Build Funnel
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}>
            <AddIcon />
            Create New Funnel
          </Button>
        </div>
      </Grid>
      <Divider my={6} />
      <Grid>
        <Typography variant="h3" gutterBottom display="inline">
          Funnels
        </Typography>
      </Grid>
      <FunnelTable tableTitle={'Funnel Table'} data={dummyData} />
    </React.Fragment>
  );
};
