import { Box, CircularProgress, Paper } from '@mui/material';
import PageHeader from '../../common/PageHeader/PageHeader';
import { useParams } from 'react-router-dom';
import CreateUpdateUserForm from '../CreateUpdateAdminForm/CreateUpdateUserForm';
import { Tenant } from '../../../types/api/Tenant';
import { useGetTenantUserListQuery } from '../../../store/admin';
import { TYPE_USER_ROLES } from '../../../utils/auth';
import { useMemo } from 'react';

interface Props {
  userRole: TYPE_USER_ROLES;
  pageHeader: string;
}

export default function CreateUpdateAdmin({ userRole, pageHeader }: Props) {
  const { user_id } = useParams();

  const { data: admins } = useGetTenantUserListQuery({
    userRole: userRole,
  });

  const admin: Tenant | undefined = useMemo(() => {
    return user_id
      ? admins?.Items.find((admin) => admin.userId === user_id)
      : undefined;
  }, [admins, user_id]);

  return (
    <Paper sx={{ p: 4 }}>
      <PageHeader text={pageHeader} />
      {!!user_id && !admin ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <CreateUpdateUserForm admin={admin!} userRole={userRole} />
      )}
    </Paper>
  );
}
