import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rotatorsApi } from './rotators';
import { linksApi } from './links';
import { adminApi } from './admin';

export const store = configureStore({
  reducer: {
    [rotatorsApi.reducerPath]: rotatorsApi.reducer,
    [linksApi.reducerPath]: linksApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rotatorsApi.middleware,
      linksApi.middleware,
      adminApi.middleware,
    ),
});

setupListeners(store.dispatch);
