import { Typography } from '@mui/material';
import { dictionary } from '../../../utils/dictionary';
import React from 'react';
import UserList from '../AdminList/UserList';
import { TYPE_USER_ROLES, USER_ROLES } from '../../../utils/auth';
import { RouterPath } from '../../../utils/routerUtils';

interface Props {
  currentUserRole: TYPE_USER_ROLES;
}

export default function TenantAdmin({ currentUserRole }: Props) {
  return (
    <>
      <Typography variant="h3" gutterBottom display="inline">
        {dictionary.admin.tenantAdminPageHeader}
      </Typography>
      <UserList
        currentUserRole={currentUserRole}
        userRole={USER_ROLES.TENANT_USER}
        pathToCreate={RouterPath.CREATE_TENANT_USER}
        pathToUpdate={RouterPath.UPDATE_TENANT_USER}
        pageSubHeader={dictionary.admin.tenantUsersTableHeader}
        createButtonLabel={dictionary.admin.createTenantUser}
      />
    </>
  );
}
