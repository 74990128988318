import {Box, Typography} from "@mui/material";
import {FC} from "react";

interface Props {
    label: string,
    value?: string | number,
}

export const DetailRow: FC<Props>  = ({label, value}) => {
    return <Box display="flex" flexDirection="row" gap={2} alignItems="baseline">
        <Typography whiteSpace='nowrap' variant="subtitle2">{label}</Typography>
        <Typography>{value}</Typography>
    </Box>
}
