import * as APITypes from '../../types/api/TrafficSources';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCbaAppTrafficSources =
  /* GraphQL */ `query ListCbaAppTrafficSources {
  listCbaAppTrafficSources {
    items {
      traffic_source_full_name
      traffic_source_id
      channel
      traffic_source_abbreviation
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.TrafficSourcesQueryVariables,
    APITypes.ListCbaAppTrafficSourcesQuery
  >;
