import React from 'react';
import {
  Alert as MuiAlert,
  Button as MuiButton,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  updateUserAttributes,
  sendUserAttributeVerificationCode,
} from 'aws-amplify/auth';

const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Alert = styled(MuiAlert)(spacing);

interface initialValuesType {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  streetAddressOne: string;
  streetAddressTwo: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  submit: boolean;
}

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().max(255).required('First name is required'),
  lastName: Yup.string().max(255).required('Last name is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  companyName: Yup.string().max(255).required('Company name is required'),
  streetAddressOne: Yup.string()
    .max(255)
    .required('Street address 1 is required'),
  city: Yup.string().max(255).required('City is required'),
  state: Yup.string().max(255).required('State is required'),
  zipcode: Yup.number().max(100000).required('Zip Code is required'),
  country: Yup.string().max(255).required('Country is required'),
});

interface profileAttrPropsType {
  setSnackBarMsg: (msg: string) => void;
  setSnackBarOpen: (open: boolean) => void;
}

export const ProfileAttributes = (profileAttrProps: profileAttrPropsType) => {
  const initialValues: initialValuesType = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    streetAddressOne: '',
    streetAddressTwo: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    submit: false,
  };

  /**
   * Update the user's attributes.
   * Send email validation link.
   * Then, create the advertiser id.
   * @param values
   * @param setErrors
   * @param setStatus
   * @param setSubmitting
   */
  const handleSubmitAction = async (
    values: initialValuesType,
    { setErrors, setStatus, setSubmitting }: any,
  ) => {
    try {
      const {
        firstName,
        lastName,
        email,
        companyName,
        streetAddressOne,
        streetAddressTwo,
        city,
        state,
        zipcode,
        country,
      } = values;

      await updateUserAttributes({
        userAttributes: {
          name: `${firstName} ${lastName}`,
          email: `${email}`,
          address: JSON.stringify({
            streetAddressOne,
            streetAddressTwo,
            city,
            state,
            zipcode,
            country,
          }),
          family_name: `${companyName}`,
        },
      });

      // This sends the email validation code. Need it to send the link.
      //Auth sends email url

      await sendUserAttributeVerificationCode({
        userAttributeKey: 'email',
      });
      profileAttrProps.setSnackBarMsg('Successfully updated your profile.');
      profileAttrProps.setSnackBarOpen(true);

      // navigate("/");
    } catch (error: any) {
      const message = error.message || 'Something went wrong';

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        User Profile
      </Typography>
      <Typography variant="subtitle2" align="center" gutterBottom>
        Let's get to know you so more.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmitAction}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="text"
              name="companyName"
              label="Company name"
              value={values.companyName}
              error={Boolean(touched.companyName && errors.companyName)}
              fullWidth
              helperText={touched.companyName && errors.companyName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="firstName"
              label="First name"
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="lastName"
              label="Last name"
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="streetAddressOne"
              label="Street address 1"
              value={values.streetAddressOne}
              error={Boolean(
                touched.streetAddressOne && errors.streetAddressOne,
              )}
              fullWidth
              helperText={touched.streetAddressOne && errors.streetAddressOne}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="streetAddressTwo"
              label="Street address 2"
              value={values.streetAddressTwo}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="city"
              label="City"
              value={values.city}
              error={Boolean(touched.city && errors.city)}
              fullWidth
              helperText={touched.city && errors.city}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="state"
              label="State"
              value={values.state}
              error={Boolean(touched.state && errors.state)}
              fullWidth
              helperText={touched.state && errors.state}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="zipcode"
              label="Zipcode"
              value={values.zipcode}
              error={Boolean(touched.zipcode && errors.zipcode)}
              fullWidth
              helperText={touched.zipcode && errors.zipcode}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="text"
              name="country"
              label="Country"
              value={values.country}
              error={Boolean(touched.country && errors.country)}
              fullWidth
              helperText={touched.country && errors.country}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}>
              Update User Information
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
