import * as APITypes from '../../types/api/Link';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listLinks = /* GraphQL */ `query Link {
  listLinks {
    items {
      user_id
      link
      advertiser_id
      advertiser_name
      offer_id
      offer_name
      rotator_id
      rotator_name
      url_id
      url_name
      id
    }
  }
}
` as GeneratedQuery<APITypes.LinkQueryVariables, APITypes.LinkQuery>;
