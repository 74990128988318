import { Advertiser } from '../../../types/api/Advertiser';

export function getUniqueByAdvertiserId(
  arr: { advertiser_id: number }[] | undefined,
) {
  if (!arr) {
    return [];
  }

  let mapObj = new Map();

  arr.forEach((v: any) => {
    let prevValue = mapObj.get(v.advertiser_id);
    if (!prevValue) {
      mapObj.set(v.advertiser_id, v);
    }
  });

  return Array.from(mapObj.values());
}

export function convertAdvertiserToOptionValue(advertiser?: Advertiser) {
  return {
    advertiser_id: advertiser?.advertiser_id,
    advertiser_name: advertiser?.advertiser_name,
  };
}

export function convertAdvertisersToOptions(advertisers?: Advertiser[]) {
  return (
    advertisers?.map((advertiser) => ({
      value: convertAdvertiserToOptionValue(advertiser),
      label: advertiser.advertiser_name,
      id: advertiser.advertiser_id,
    })) || []
  );
}
