import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { Badge } from '@mui/material';
import {
  getGridNumericOperators,
  GridAggregationFunction,
} from '@mui/x-data-grid-premium';
import React from 'react';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { GridColDef } from '@mui/x-data-grid';

export const TABLE_VIEW_STORAGE_KEY = 'rotatorTableView';

const groupFirstValueGetter =
  (fieldName: string) => (params: GridRenderCellParams) => {
    let theFirstChildId;
    if ('children' in params.rowNode) {
      theFirstChildId = params.rowNode.children[0];
      const state = params.api.state;
      const theFirstChild = state.rows.dataRowIdToModelLookup[theFirstChildId];

      return theFirstChild && theFirstChild[fieldName];
    }

    return '';
  };

export const customAllStatusAggregationFunc: GridAggregationFunction<
  string,
  string
> = {
  label: 'sum',
  apply: ({ values, field }) => {
    const hasActive = values.some((value) => value === 'active');
    return hasActive ? 'active' : 'paused';
  },
  columnTypes: ['singleSelect'],
};

export const rotatorsColumns: Array<GridColDef> = [
  {
    field: '__row_group_by_columns_group__',
    filterable: false,
    width: 140,
  },
  {
    field: 'rotator_name',
    headerName: 'Rotator name',
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === 'contains' || operator.value === 'equals',
    ),
    valueGetter: groupFirstValueGetter('rotator_name'),
    width: 440,
    hideable: false,
    disableReorder: true,
  },
  {
    field: 'rotator_id',
    headerName: 'Rotator variants',
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === '=' ||
        operator.value === '>' ||
        operator.value === '<',
    ),
    valueGetter: groupFirstValueGetter('rotator_id'),
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      let childrenCount;

      if ('children' in params.rowNode) {
        childrenCount = params.rowNode.children.length;
        return (
          <>
            {params.field !== 'rotator_id' && params.value}
            <Badge
              sx={{ mr: 4, ml: 4 }}
              badgeContent={childrenCount}
              color="primary"
            />
          </>
        );
      }

      return params.value;
    },
  },
  {
    field: 'offer_name',
    headerName: 'Offer',
    valueGetter: groupFirstValueGetter('offer_name'),
    width: 260,
  },
  {
    field: 'rotator_content_status',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: [
      {
        value: 'active',
        label: 'active',
      },
      {
        value: 'paused',
        label: 'paused',
      },
    ],
    filterOperators: getGridSingleSelectOperators().filter(
      (operator) => operator.value === 'is',
    ),
    sortable: false,
  },
  {
    field: 'advertiser_name',
    headerName: 'Advertiser name',
    width: 260,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === 'contains' || operator.value === 'equals',
    ),
    sortable: false,
    valueGetter: groupFirstValueGetter('advertiser_name'),
  },
  {
    field: 'rotator_content_custom_weight',
    headerName: 'Weight',
    width: 100,
    filterable: false,
    sortable: false,
    availableAggregationFunctions: ['max', 'min', 'sum'],
    valueFormatter: (params: GridValueFormatterParams) => {
      return `${(Math.round(params.value * 100) / 100).toFixed(2)}%`;
    },
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.rotator_content_status === 'active'
        ? params.row.rotator_content_custom_weight
        : 0;
    },
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'total_clicks',
    headerName: 'Clicks',
    availableAggregationFunctions: ['max', 'min', 'sum'],
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value ? params.value.toLocaleString('en-US') : undefined;
    },
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'total_conversions',
    headerName: 'Conversions',
    filterable: false,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'total_revenue',
    headerName: 'Revenue',
    filterable: false,
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value
        ? `$${Math.floor(params.value).toLocaleString('en-US')}`
        : undefined;
    },
    align: 'left',
    headerAlign: 'left',
  },
];

export const getColumns = () => {
  const serializedState = localStorage.getItem(TABLE_VIEW_STORAGE_KEY);

  if (serializedState) {
    const { columns } = JSON.parse(serializedState);

    return columns
      ? rotatorsColumns.map((col) => ({
          ...col,
          width: columns.lookup[col.field]?.width || col.width,
        }))
      : rotatorsColumns;
  }

  return rotatorsColumns;
};

export const getInitialState = (): GridInitialStatePremium => {
  const serializedState = localStorage.getItem(TABLE_VIEW_STORAGE_KEY);

  const restoredState = serializedState ? JSON.parse(serializedState) : null;

  const initialColumns = restoredState?.columns || {
    columnVisibilityModel: {
      rotator_content_id: false,
      rotator_content_status: false,
    },
  };

  const initialSortModel = restoredState?.sortModel || [
    { field: 'rotator_name', sort: 'asc' },
  ];

  return {
    rowGrouping: {
      model: ['rotator_id'],
    },
    sorting: {
      sortModel: initialSortModel,
    },
    columns: initialColumns,
    pagination: { paginationModel: { pageSize: 10, page: 0 } },
    filter: {
      filterModel: {
        items: [
          { field: 'rotator_content_status', operator: 'is', value: 'active' },
        ],
      },
    },
    aggregation: {
      model: {
        total_clicks: 'sum',
        rotator_content_custom_weight: 'sum',
        total_conversions: 'sum',
        total_revenue: 'sum',
        rotator_content_status: 'statusAggr',
      },
    },
  };
};
