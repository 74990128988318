import { Typography } from '@mui/material';
import { dictionary } from '../../../utils/dictionary';
import React from 'react';
import UserList from '../AdminList/UserList';
import { TYPE_USER_ROLES, USER_ROLES } from '../../../utils/auth';
import { RouterPath } from '../../../utils/routerUtils';

interface Props {
  currentUserRole: TYPE_USER_ROLES;
}
export default function Superamin({ currentUserRole }: Props) {
  return (
    <>
      <Typography variant="h3" gutterBottom display="inline">
        {dictionary.admin.superAdminPageHeader}
      </Typography>
      <UserList
        currentUserRole={currentUserRole}
        userRole={USER_ROLES.SYSTEM_ADMIN}
        pathToCreate={RouterPath.CREATE_ADMIN}
        pathToUpdate={RouterPath.UPDATE_ADMIN}
        pageSubHeader="System admins"
        createButtonLabel={dictionary.admin.createAdminButton}
      />
      <UserList
        currentUserRole={currentUserRole}
        userRole={USER_ROLES.TENANT_ADMIN}
        pathToCreate={RouterPath.CREATE_TENANT}
        pathToUpdate={RouterPath.UPDATE_TENANT}
        pageSubHeader="Tenant admins"
        createButtonLabel={dictionary.admin.createTenantAdminButton}
      />
    </>
  );
}
