import {GridActionsCellItem} from "@mui/x-data-grid-pro";
import React from "react";
import {useNavigate} from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Props {
    url: string
}

export default function TableViewActionCell({url}: Props) {
    const navigate = useNavigate();

    return (<GridActionsCellItem
        icon={<OpenInNewIcon />}
        label="Save"
        sx={{
            color: 'primary.main',
        }}
        onClick={() => {
            navigate(url)
        }}
    />)
}
