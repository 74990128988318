import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { DetailRow } from '../../common/DetailRow/DetailRow';
import { useParams } from 'react-router-dom';
import {
  percentageFormatting,
  revenueFormatting,
} from '../RotatorsSubTable/utils';
import BackButton from '../../common/BackButton/BackButton';
import { useGetRotatorQuery } from '../../../store/rotators';

export const RotatorDetails = () => {
  const { rotator_id, rotator_content_id } = useParams();

  const { data: rotator, isLoading } = useGetRotatorQuery({
    rotator_id: +rotator_id!,
    rotator_content_id: +rotator_content_id!,
  });

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <BackButton />
      </Box>
      <Typography mb={4} variant="h2">
        Rotator Content Details
      </Typography>
      <Paper
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}>
        {isLoading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {rotator && (
          <>
            <DetailRow label="Rotator name:" value={rotator.rotator_name} />
            <DetailRow label="Rotator id:" value={rotator.rotator_id} />
            <DetailRow label="Status:" value={rotator.rotator_content_status} />
            <DetailRow
              label="Advertiser name:"
              value={rotator.advertiser_name}
            />
            <DetailRow
              label="Weight:"
              value={
                rotator.rotator_content_custom_weight &&
                percentageFormatting(rotator.rotator_content_custom_weight)
              }
            />
            <DetailRow label="Status:" value={rotator.rotator_content_status} />
            <DetailRow label="Offer:" value={rotator.offer_name} />
            <DetailRow label="Page name:" value={rotator.url_name} />
            <DetailRow label="Page Id:" value={rotator.offer_url_id} />
            <DetailRow
              label="Clicks:"
              value={rotator.total_clicks?.toLocaleString('en-US')}
            />
            <DetailRow label="Conversions:" value={rotator.total_clicks} />
            <DetailRow
              label="Revenue:"
              value={revenueFormatting(rotator.total_revenue)}
            />
            <DetailRow label="Last modified:" value={rotator.last_modified} />
            <DetailRow label="First seen:" value={rotator.last_seen_local} />
            <DetailRow label="Last seen:" value={rotator.first_seen_local} />
          </>
        )}
      </Paper>
    </Box>
  );
};
