import * as APITypes from '../../types/api/Affiliate';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCbaAffiliates = /* GraphQL */ `query ListCbaAffiliates(
  $filter: FilterInput
) {
  listCbaAffiliates(
    filter: $filter
  ) {
    items {
         affiliate_id
         affiliate_name
         aff_abbrev
         advertiser_id
         advertiser_name
         total_volume
         total_revenue
         first_seen_local
         last_seen_local
         channel
    }
  }
}
` as GeneratedQuery<
  APITypes.AffiliateQueryVariables,
  APITypes.ListAffiliateQuery
>;
