export enum RouterPath {
  MAIN = '/',
  OPTIMIZE = '/optimize',
  CREATE = '/create',
  TOOLS = '/tools',
  FUNNEL = '/tools/funnel',
  ADMIN = '/admin',
  CREATE_TENANT = '/admin/tenant/create',
  UPDATE_TENANT = '/admin/tenant/update/:user_id',
  CREATE_ADMIN = '/admin/create',
  UPDATE_ADMIN = '/admin/update/:user_id',
  CREATE_TENANT_USER = '/admin/tenantUser/create',
  UPDATE_TENANT_USER = '/admin/tenantUser/update/:user_id',
  DOCUMENTATION = '/documentation',
  CHANGELOG = '/changelog',
  PROFILE = '/profile',
  ROTATORS = '/rotators',
  ROTATOR_DETAILS = 'details/:rotator_content_id/:rotator_id',
  NEW_LINK = '/links/new-link',
  LINKS = '/links',
}
