import { Typography } from '@mui/material';

interface Props {
  text: string;
}

export default function PageHeader({ text }: Props) {
  return (
    <Typography variant="h3" mb={4}>
      {text}
    </Typography>
  );
}
