import * as APITypes from '../../types/api/GroupPermission';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listGroupPermissions = /* GraphQL */ `query ListGroupPermissions(
  $filter: ModelGroupPermissionsFilterInput
) {
  listGroupPermissions(
    filter: $filter
  ) {
    items {
         id
         group_name
         allowed_advertiser
    }
  }
}
` as GeneratedQuery<
  APITypes.GroupPermissionQueryVariables,
  APITypes.ListGroupPermissionQuery
>;
