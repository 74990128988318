import { ConfirmationDialogProps } from '../components/common/ConfirmationDialog/ConfirmationDialog';
import { useState } from 'react';

export function useConfirmDialog({ text }: { text: string }) {
  const handleClose = () =>
    setConfirmationDialogProps((prevState: ConfirmationDialogProps) => ({
      ...prevState,
      open: false,
    }));

  const [confirmationDialogProps, setConfirmationDialogProps]: [
    ConfirmationDialogProps,
    (prevState: any) => void,
  ] = useState<ConfirmationDialogProps>({
    open: false,
    handleClose: handleClose,
    text: text,
  });

  const openDialog = (onConfirm: () => void) => {
    setConfirmationDialogProps((prevState: ConfirmationDialogProps) => ({
      ...prevState,
      open: true,
      handleConfirm: () => {
        onConfirm();
        handleClose();
      },
    }));
  };

  return {
    confirmationDialogProps,
    openDialog,
  };
}
