import React from 'react';

import '@aws-amplify/ui-react/styles.css';

import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createTheme from './theme';
import useTheme from './hooks/useTheme';
import { RouterWrapper } from './routes';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import AuthenticatorWrapper from './components/Auth/AuthenticatorWrapper/AuthenticatorWrapper';

import 'typeface-roboto';
import { configureAmplify } from './utils/amplifyUtils';

configureAmplify();

LicenseInfo.setLicenseKey(
  'f359d9c0d105599a7d83c3f8d775eca5Tz0xMjMsRT0yNTI0NjA0NDAwMDAwLFM9cHJlbWl1bSxMTT1wZXJwZXR1YWwsS1Y9Mg==',
);

const App = () => {
  const theme = useTheme();

  return (
    <MuiThemeProvider theme={createTheme(theme.theme)}>
      <AuthenticatorWrapper>
        {() => (
          <MuiThemeProvider theme={createTheme(theme.theme)}>
            <RouterWrapper />
          </MuiThemeProvider>
        )}
      </AuthenticatorWrapper>
    </MuiThemeProvider>
  );
};

export default App;
