import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { RotatorsTable } from '../../components/Rotators/RotatorsTable/RotatorsTable';
import { CbaAppRotatorContents } from '../../types/api/Rotators';
import { filterRotators } from './utils';
import { GridFilterModel } from '@mui/x-data-grid-premium';
import { Outlet, useOutlet } from 'react-router-dom';
import { useGetRotatorsListQuery } from '../../store/rotators';

const defaultFilterModel = {
  items: [
    {
      field: 'rotator_content_status',
      operator: 'is',
      value: 'active',
    },
  ],
};

export const Rotators = () => {
  const [filterModel, setFilterModel] =
    useState<GridFilterModel>(defaultFilterModel);

  const { data: rotators, isLoading } = useGetRotatorsListQuery();

  const [filteredRotators, setFilteredRotators] = useState<
    Array<CbaAppRotatorContents>
  >([]);

  const outlet = useOutlet();

  useEffect(() => {
    if (rotators) {
      const filteredItems = filterRotators(filterModel, rotators);
      setFilteredRotators(filteredItems);
    }
  }, [filterModel, rotators]);

  return (
    <>
      <Box position="relative">
        <RotatorsTable
          loading={isLoading}
          rows={filteredRotators}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
        />
        {outlet && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              background: (theme) => theme.palette.background.default,
              zIndex: 2,
            }}>
            <Outlet />
          </Box>
        )}
      </Box>
    </>
  );
};
