import { Box, Button, Divider } from '@mui/material';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import React, { useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {
  AccessType,
  defaultAccessItem,
  TenantForm,
} from '../../../types/api/Tenant';
import AdvertisersRestrictionsForm from '../AdvertisersRestrictionsForm/AdvertisersRestrictionsForm';
import AddIcon from '@mui/icons-material/Add';
import { USER_ROLES } from '../../../utils/auth';
import { useTenantAdmin } from '../../../hooks/useTenantAdmin';
import { useParams } from 'react-router-dom';

interface Props {
  accessType: AccessType;
  headerText?: string;
}

export default function AdvertisersRestrictions({
  accessType,
  headerText,
}: Props) {
  const { user_id } = useParams();

  const isUpdating = !!user_id;

  const { values, setFieldValue } = useFormikContext<TenantForm>();

  const isTenantUser = values.userRole === USER_ROLES.TENANT_USER;

  const { tenantAdmin } = useTenantAdmin({
    skip: !isTenantUser || isUpdating,
  });

  useEffect(() => {
    if (!!tenantAdmin && !isUpdating) {
      setFieldValue(`${accessType}`, tenantAdmin[accessType]);
    }
  }, [tenantAdmin, accessType, isUpdating, setFieldValue]);

  return (
    <Box
      pt={4}
      ml={8}
      width="97%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      gap={4}>
      {headerText && <PageSubHeader text={headerText} />}
      <FieldArray
        name={accessType}
        render={(arrayHelpers) => (
          <>
            {values[accessType]?.map((item, index) => (
              <>
                <AdvertisersRestrictionsForm
                  key={item.advertiserId}
                  index={index}
                  onDelete={(i) => arrayHelpers.remove(i)}
                  accessType={accessType}
                />
                {index < values[accessType]?.length - 1 && (
                  <Divider sx={{ my: 3, mx: 4 }} />
                )}
              </>
            ))}
          </>
        )}
      />

      <Button
        sx={{ paddingTop: '4px', alignSelf: 'flex-start' }}
        onClick={() =>
          setFieldValue(
            `${accessType}[${values[accessType].length}]`,
            defaultAccessItem,
          )
        }>
        <AddIcon /> ADD ADVERTISER
      </Button>
    </Box>
  );
}
