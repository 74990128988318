import { Box } from '@mui/material';
import { pageColumns, pegeDestinationsTableInitialState } from './utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { dictionary } from '../../../utils/dictionary';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import {
  DataGridPremium,
  GridFilterModel,
  GridPaginationModel,
} from '@mui/x-data-grid-premium';
import { GridRowId } from '@mui/x-data-grid-pro';
import CustomGridToolbar from '../../Rotators/CustomGridToolbar/CustomGridToolbar';
import { useGetPageDestinationListQuery, linksApi } from '../../../store/links';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { TableCbaPageDestinationsSortingInput } from '../../../types/api/PageDestination';
import { useDispatch } from 'react-redux';
import { FilterItem } from '../../../types/api/common';

const defaultFilterModel = {
  items: [],
};

const defaultPaginationModel = {
  pageSize: 10,
  page: 0,
};

export default function PageStep() {
  const dispatch = useDispatch();

  const { setFieldValue, values } = useFormikContext<RotatorLinkForm>();

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>(
    values.pageDestination ? [values.pageDestination.key] : [],
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    defaultPaginationModel,
  );

  const [sortModel, setSortModel] = useState<GridSortModel>();

  const [filterModel, setFilterModel] =
    useState<GridFilterModel>(defaultFilterModel);

  const defaultFilterItem = {
    field: 'advertiser_id',
    operator: '=',
    value: `${values.advertiser?.advertiser_id}`,
  };

  const {
    data: pageDestinations,
    isLoading,
    isFetching,
  } = useGetPageDestinationListQuery({
    pagination: paginationModel,
    sorting:
      sortModel && (sortModel[0] as TableCbaPageDestinationsSortingInput),
    filter: {
      items: [defaultFilterItem, ...(filterModel.items as FilterItem[])],
      logicOperator: filterModel.logicOperator || 'and',
      quickFilterValue: filterModel.quickFilterValues?.[0],
    },
  });

  const fetchNextPage = useCallback(
    (page: number) => {
      dispatch(
        linksApi.endpoints?.getPageDestinationList.initiate(
          {
            pagination: {
              ...paginationModel,
              page: page + 1,
            },
            sorting:
              sortModel &&
              (sortModel[0] as TableCbaPageDestinationsSortingInput),
            filter: {
              items: filterModel.items as FilterItem[],
              logicOperator: filterModel.logicOperator || 'and',
              quickFilterValue: filterModel.quickFilterValues?.[0],
            },
          },
          { subscribe: false, forceRefetch: false },
        ),
      );
    },
    [paginationModel, sortModel, filterModel, dispatch],
  );

  useEffect(() => {
    setPaginationModel(defaultPaginationModel);
  }, [filterModel]);

  useEffect(() => {
    fetchNextPage(0);
  }, [fetchNextPage]);

  return (
    <Box>
      <PageSubHeader text={dictionary.links.newLink.pageStepHeader} />
      <Box
        sx={{
          height: 'calc(100vh - 390px)',
          minHeight: '240px',
          width: '100%',
        }}>
        <DataGridPremium
          slots={{ toolbar: CustomGridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 600 },
              filterProps: { debounceMs: 600 },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              excelOptions: { disableToolbarButton: true },
            },
            panel: {
              sx: {
                top: '-200px !important',
              },
            },
          }}
          disableDensitySelector
          filterMode="server"
          onFilterModelChange={(newFilterModel) => {
            setFilterModel(newFilterModel);
          }}
          columns={pageColumns}
          rows={pageDestinations?.items || []}
          loading={isLoading || isFetching}
          getRowId={(row: any) => row?.key}
          paginationMode="server"
          rowCount={pageDestinations?.totalCount || 0}
          onPaginationModelChange={(newPaginationModel) => {
            if (newPaginationModel.page > paginationModel.page) {
              fetchNextPage(newPaginationModel.page);
            }
            setPaginationModel(newPaginationModel);
          }}
          paginationModel={paginationModel}
          pagination
          disableMultipleRowSelection
          checkboxSelection
          disableRowSelectionOnClick
          rowSelectionModel={selectionModel}
          sortingMode="server"
          onSortModelChange={(sortModel: GridSortModel) =>
            setSortModel(sortModel)
          }
          initialState={pegeDestinationsTableInitialState}
          onRowSelectionModelChange={(selection) => {
            const selectionSet = new Set(selectionModel);
            const result = selection.filter((s) => !selectionSet.has(s));

            const pageDestination = pageDestinations?.items?.find(
              (destination) => destination?.key === result[0],
            );

            setFieldValue('pageDestination', pageDestination);

            if (selection.length > 1) {
              setSelectionModel(result);
            } else {
              setSelectionModel(selection);
            }
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
              {
                display: 'none',
              },
          }}
        />
      </Box>
    </Box>
  );
}
