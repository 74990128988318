import { dictionary } from '../../../utils/dictionary';
import TenantAdminInfoStep from '../CreateUpdateTenantForm/TenantAdminInfoStep';
import AdvertisersRestrictions from '../AdvertisersRestrictionsStep/AdvertisersRestrictions';

export const steps = [
  {
    label: dictionary.admin.tenantAdminInfo,
    content: <TenantAdminInfoStep />,
  },
  {
    label: dictionary.admin.adminAccessStep,
    content: (
      <AdvertisersRestrictions
        accessType="adminAccess"
        headerText={dictionary.admin.adminAccessStepHeader}
      />
    ),
  },
  {
    label: dictionary.admin.writeAccessStep,
    content: (
      <AdvertisersRestrictions
        accessType="writeAccess"
        headerText={dictionary.admin.writeAccessStepHeader}
      />
    ),
  },
  {
    label: dictionary.admin.readAccessStep,
    content: (
      <AdvertisersRestrictions
        accessType="readAccess"
        headerText={dictionary.admin.readAccessStepHeader}
      />
    ),
  },
];
