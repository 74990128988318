import React, { useEffect } from 'react';

import { getUserRole, TYPE_USER_ROLES, USER_ROLES } from '../utils/auth';
import Superamin from '../components/Admin/Superadmin/Superamin';
import TenantAdmin from '../components/Admin/TenantAdmin/TenantAdmin';

export const Admin = () => {
  const [userRole, setUserRole] = React.useState<TYPE_USER_ROLES | null>(null);

  useEffect(() => {
    async function setRole() {
      const userRole = await getUserRole();
      setUserRole(userRole);
    }
    setRole();
  }, []);

  return (
    <React.Fragment>
      {(userRole === USER_ROLES.SUPER_ADMIN ||
        userRole === USER_ROLES.SYSTEM_ADMIN) && (
        <Superamin currentUserRole={userRole} />
      )}
      {userRole === USER_ROLES.TENANT_ADMIN && (
        <TenantAdmin currentUserRole={userRole} />
      )}
    </React.Fragment>
  );
};
