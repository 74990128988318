import {CbaAppRotatorContents, TableCbaAppRotatorContentsFilterInput} from "../../types/api/Rotators";
import {
    GridFilterModel,
} from "@mui/x-data-grid-pro";

export const filterRotators = (filterModel: GridFilterModel, rotators: Array<CbaAppRotatorContents> ) => {
    let filteredItems = rotators;

    const quickFilterValue = filterModel.quickFilterValues && filterModel.quickFilterValues[0];

    if (quickFilterValue && quickFilterValue !== '') {
        if (isNumeric(quickFilterValue)) {
            const filterFunc = filterOperatorsFunctions['='];

            filteredItems = rotators.filter(rotator =>
                filterFunc(rotator.rotator_content_id, quickFilterValue) ||
                filterFunc(rotator.rotator_id, quickFilterValue) ||
                filterFunc(rotator.advertiser_id, quickFilterValue) ||
                filterFunc(rotator.offer_file_id, quickFilterValue) ||
                filterFunc(rotator.offer_url_id, quickFilterValue) ||
                filterFunc(rotator.offer_id, quickFilterValue) ||
                filterFunc(rotator.rotator_content_custom_weight, quickFilterValue) ||
                filterFunc(rotator.rotator_content_weight, quickFilterValue)
            );
        } else {
            const filterFunc = filterOperatorsFunctions.contains;

            filteredItems = rotators.filter(rotator =>
                filterFunc(rotator.rotator_name, quickFilterValue) ||
                filterFunc(rotator.advertiser_name, quickFilterValue) ||
                filterFunc(rotator.offer_name, quickFilterValue) ||
                filterFunc(rotator.tune_client_account, quickFilterValue) ||
                filterFunc(rotator.url_name, quickFilterValue) ||
                filterFunc(rotator.offer_url, quickFilterValue) ||
                filterFunc(rotator.preview_url, quickFilterValue)
            );
        }
    }

    if (filterModel.items && filterModel.items.length > 0) {
        if (!filterModel.logicOperator || filterModel.logicOperator === 'and') {
            filterModel.items.forEach(item => {
                const operator = item.operator as 'is'
                const field = item.field === "__row_group_by_columns_group__" ? "rotator_id" : item.field as keyof CbaAppRotatorContents;
                const value = item.value;

                const filterFunc = filterOperatorsFunctions[operator];
                if (!!filterFunc && value && value !== '') {
                    filteredItems = filteredItems.filter(rotator => filterFunc(rotator[field] as string, value));
                }
            })
        } else {
            filteredItems = filteredItems.filter(rotator => {
                return filterModel.items.some(item => {
                    const operator = item.operator as keyof typeof filterOperatorsFunctions;
                    const field = item.field === "__row_group_by_columns_group__" ? "rotator_id" : item.field as keyof CbaAppRotatorContents;
                    const value = item.value;

                    const filterFunc = filterOperatorsFunctions[operator];

                    if (!!filterFunc && value && value !== '') {
                        return filterFunc(rotator[field] as never, value)
                    }
                    return false;
                });
            })
        }
    }

    return filteredItems;
}

const filterOperatorsFunctions = {
    "is": (item1: string, item2: string) => item1 === item2,
    "=": (item1: number, item2: string) => '' + item1 === item2,
    "equals": (item1: string, item2: string) => item1?.toLowerCase() === item2?.toLowerCase(),
    "contains": (item1: string, item2: string) => item1?.toLowerCase().includes(item2?.toLowerCase()),
    ">": (item1: number, item2: string) => item1 > +item2,
    "<": (item1: number, item2: string) => item1 < +item2,
}

export const convertFilterModelToFilter = (filterModel?: GridFilterModel): TableCbaAppRotatorContentsFilterInput | undefined => {

    if (!filterModel) {
        return undefined;
    }

    let filter;

    const quickFilterValue = filterModel.quickFilterValues && filterModel.quickFilterValues[0];

    if (quickFilterValue && quickFilterValue !== '') {
        const orFilters: Array<TableCbaAppRotatorContentsFilterInput> = [
            {rotator_name: {contains: quickFilterValue}},
            {advertiser_name: {contains: quickFilterValue}},
            {offer_name: {contains: quickFilterValue}},
            {tune_client_account: {contains: quickFilterValue}},
            {url_name: {contains: quickFilterValue}},
            {offer_url: {contains: quickFilterValue}},
            {preview_url: {contains: quickFilterValue}}
        ]

        if (isNumeric(quickFilterValue)) {
            orFilters.push({rotator_content_id: {eq: quickFilterValue}})
            orFilters.push({rotator_id: {eq: quickFilterValue}})
            orFilters.push({advertiser_id: {eq: quickFilterValue}})
            orFilters.push({offer_file_id: {eq: quickFilterValue}})
            orFilters.push({offer_url_id: {eq: quickFilterValue}})
            orFilters.push({offer_id: {eq: quickFilterValue}})
            orFilters.push({rotator_content_custom_weight: {eq: quickFilterValue}})
            orFilters.push({rotator_content_weight: {eq: quickFilterValue}})
        }

        filter = {
            or: orFilters
        }
    }

    if (filterModel.items && filterModel.items.length > 0) {
        let filters: Array<TableCbaAppRotatorContentsFilterInput> = [];


        filterModel.items.forEach(item => {
            const field = item.field === "__row_group_by_columns_group__" ? "rotator_id" : item.field;

            if (item.value) {
                // @ts-ignore
                filters.push({[field]: {[operatorMapping[item.operator]]: item.value}})
            }
        });

        if (filters && filters.length > 0) {
            if (!filterModel.logicOperator || filterModel.logicOperator === 'and') {
                filter = {...filter, and: filters}
            } else if (filterModel.logicOperator === 'or') {
                filter = {...filter, or: filters}
            }
        }
    }

    return filter;
}

const operatorMapping = {
    "=": "eq",
    ">": "gt",
    "<": "lt",
    "contains": "contains",
    "equals": "eq",
    "is": "eq",
}

function isNumeric(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}
