import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { dictionary } from '../../../utils/dictionary';

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(-1)}>
      <ArrowBackIcon sx={{ mr: 2 }} />
      {dictionary.buttons.goBack}
    </Button>
  );
}
