import { SidebarItemsType } from '../../types/sidebar';

import {
  BookOpen,
  List,
  Sliders,
  Users,
  BarChart,
  Link,
  Settings,
} from 'react-feather';
import { dictionary } from '../../utils/dictionary';
import { RouterPath } from '../../utils/routerUtils';

const pagesSection = [
  {
    href: '/',
    icon: Sliders,
    title: 'Dashboard',
  },
  // we don't need these sections for now, delete it if we never need it.
  /*  {
    href: '/optimize',
    icon: Filter,
    title: 'Optimize',
  },
  {
    href: '/create',
    icon: FilePlus,
    title: 'Create',
  },
  {
    href: '/tools',
    icon: Tool,
    title: 'Tools',
  },*/
  {
    href: RouterPath.ROTATORS,
    icon: BarChart,
    title: dictionary.rotators.sidePanelTitle,
  },
  {
    href: RouterPath.LINKS,
    icon: Link,
    title: dictionary.links.sidePanelTitle,
  },
  {
    href: RouterPath.ADMIN,
    icon: Settings,
    title: 'Settings',
    isAdminProtected: true,
    children: [
      {
        href: RouterPath.ADMIN,
        icon: Users,
        title: 'Admin Panel',
        isAdminProtected: true,
      },
    ],
  },
] as SidebarItemsType[];

// doc section is hidden, delete it if we will never need it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const docsSection = [
  {
    href: '/documentation',
    icon: BookOpen,
    title: 'Documentation',
  },
  {
    href: '/changelog',
    icon: List,
    title: 'Changelog',
    badge: 'v4.1.0',
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
  // doc section is hidden, delete it if we will never need it
  /*  {
    title: 'Cerebro',
    pages: docsSection,
  },*/
];

export default navItems;
