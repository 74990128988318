import {
  Button as MuiButton,
  Card as MuiCard,
  CardActions,
} from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

interface SimpleCardProps {
  imgSrc: string;
  title: string;
  redirectLink: string;
}

export const SimpleCard = (props: SimpleCardProps) => {
  const { imgSrc, title, redirectLink } = props;
  let navigate = useNavigate();

  const redirectToLink = (url: string) => {
    navigate(`/tools/${url}`);
  };
  return (
    <Card mb={6}>
      <CardActions>
        <Button size="small" onClick={() => redirectToLink(redirectLink)}>
          <img src={imgSrc} alt={title} className={'toolsButtons'} />
        </Button>
      </CardActions>
    </Card>
  );
};
