import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { useGetLinkTrackingTemplatesListQuery } from '../../../store/links';
import { TrackingTemplate } from '../../../types/api/LinkTrackingTemplates';

export const useTrackingTemplate = (): [TrackingTemplate | null, boolean] => {
  const { values } = useFormikContext<RotatorLinkForm>();

  const { data: linkTemplates, isLoading } =
    useGetLinkTrackingTemplatesListQuery();

  const linkTemplate =
    linkTemplates?.find(
      (template) => template.channel === values.affiliate?.channel,
    ) || (linkTemplates ? linkTemplates[0] : null);

  const trackingTemplate = !!linkTemplate
    ? (linkTemplate.tracking_template as TrackingTemplate)
    : null;
  return [trackingTemplate, isLoading];
};
