import { Box, CircularProgress, Paper } from '@mui/material';
import PageHeader from '../../common/PageHeader/PageHeader';
import { dictionary } from '../../../utils/dictionary';
import { useNavigate, useParams } from 'react-router-dom';
import { Tenant, TenantForm } from '../../../types/api/Tenant';
import { useMemo } from 'react';
import {
  useCreateTenantUserMutation,
  useGetTenantUserListQuery,
  useUpdateTenantUserMutation,
} from '../../../store/admin';
import { TYPE_USER_ROLES } from '../../../utils/auth';
import { TenantSchema } from '../../../utils/validation/TenantSchema';
import { convertTenantToTenantForm } from '../CreateUpdateTenantForm/utils';
import CustomVerticalStepper from '../../common/CustomVerticalStepper/CustomVerticalStepper';
import { Formik } from 'formik';
import { RouterPath } from '../../../utils/routerUtils';
import { useSnackbar } from 'notistack';
import { steps } from './utils';
import { extractErrorMessageFromRestApi } from '../../../utils/errorMessage';

interface Props {
  userRole: TYPE_USER_ROLES;
  pageHeader: string;
}

export default function CreateUpdateTenant({ userRole, pageHeader }: Props) {
  const { user_id } = useParams();

  const [createUser, { isLoading: createLoading }] =
    useCreateTenantUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate }] =
    useUpdateTenantUserMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data: tenants } = useGetTenantUserListQuery({
    userRole: userRole,
  });

  const tenant: Tenant | undefined = useMemo(() => {
    return user_id
      ? tenants?.Items.find((tenant) => tenant.userId === user_id)
      : undefined;
  }, [tenants, user_id]);

  const handleSubmit = async (values: TenantForm) => {
    values.step = undefined;
    values.userRole = undefined;

    values.adminAccess = values.adminAccess.filter(
      (access) => !!access.advertiserId,
    );
    values.readAccess = values.readAccess.filter(
      (access) => !!access.advertiserId,
    );
    values.writeAccess = values.writeAccess.filter(
      (access) => !!access.advertiserId,
    );

    const response = tenant
      ? await updateUser({
          userId: tenant.userId,
          user: values,
          userRole: userRole,
        })
      : await createUser({
          user: values,
          userRole: userRole,
        });

    if (typeof response !== 'string' && 'error' in response) {
      const errorMessage = extractErrorMessageFromRestApi(response);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    } else {
      const successMessage = !!tenant
        ? dictionary.admin.updateUserSuccess
        : dictionary.admin.createUserSuccess;
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      navigate(RouterPath.ADMIN);
    }
  };

  return (
    <Paper sx={{ p: 4 }}>
      <PageHeader text={pageHeader} />
      {!!user_id && !tenant ? (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          validationSchema={TenantSchema}
          initialValues={{
            ...convertTenantToTenantForm(tenant),
            userRole: userRole,
          }}
          onSubmit={handleSubmit}>
          {({ handleSubmit, isValid, setFieldValue, dirty, validateForm }) => (
            <CustomVerticalStepper
              steps={steps}
              width="96%"
              onFinish={handleSubmit}
              disabledNext={
                !isValid ||
                (!user_id && !dirty) ||
                createLoading ||
                isLoadingUpdate
              }
              onNextStep={(step) => {
                validateForm();
                setFieldValue('step', step);
              }}
            />
          )}
        </Formik>
      )}
    </Paper>
  );
}
