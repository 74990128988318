import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import { dictionary } from '../../../utils/dictionary';
import React from 'react';
import { useTrackingTemplate } from './utils';
import SourceParamAutocomplete from '../SourceParamAutocomplete/SourceParamAutocomplete';

export default function ParametersStep() {
  const { values, handleChange, errors, handleBlur } =
    useFormikContext<RotatorLinkForm>();

  const [trackingTemplate, isLoading] = useTrackingTemplate();

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <PageSubHeader text={dictionary.links.newLink.parametersStepHeader} />
      {isLoading && (
        <CircularProgress
          sx={{ position: 'absolute', left: '50%', top: '50%' }}
        />
      )}
      {trackingTemplate ? (
        Object.values(trackingTemplate)
          .sort((temp1, temp2) => temp1.order_index - temp2.order_index)
          .map((field) => {
            if (!field) {
              return null;
            }

            if (field.url_param_key === 'source') {
              return <SourceParamAutocomplete field={field} />;
            }

            const isDynamic = field.param_type === 'dynamic';
            const isStatic = field.param_type === 'static';
            const value =
              isDynamic || isStatic ? field.value : values[field.url_param_key];
            const isError = !!errors[field.url_param_key];
            const helperText = isError
              ? errors[field.url_param_key]
              : field.description;

            return (
              <TextField
                required={field.required}
                disabled={isDynamic || isStatic}
                id="utlined-basic"
                label={field.name}
                variant="outlined"
                name={field.url_param_key}
                onChange={handleChange}
                value={value}
                error={isError}
                helperText={helperText}
                onBlur={handleBlur}
              />
            );
          })
      ) : (
        <Typography display={isLoading ? 'none' : 'block'}>
          No template for {values.affiliate.channel}
        </Typography>
      )}
    </Box>
  );
}
