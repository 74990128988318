import { AutocompleteOption } from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';

export const getSelectedValue = (
  options: Array<AutocompleteOption>,
  value: string,
) => {
  if (!value || value === '') {
    return null;
  }

  return (
    options.find((option) => option.value === value) || {
      value,
      label: value,
      id: value,
    }
  );
};
