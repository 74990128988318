import {
  getGridStringOperators,
  GridActionsCellItem,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import LinkItem from '../../components/common/LinkItem/LinkItem';
import { Link as LinkType } from '../../types/api/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

export const getLinkColumns = (
  handleDeleteClick: (userId: string, link: string) => () => void,
) => {
  return [
    {
      field: 'link',
      headerName: 'Url',
      filterable: false,
      renderCell: (cellValues: any) => {
        return <LinkItem link={cellValues.value} />;
      },
      width: 500,
    },
    {
      field: 'rotator_id',
      headerName: 'Rotator Id',
      filterable: false,
      width: 72,
    },
    {
      field: 'rotator_name',
      headerName: 'Rotator name',
      width: 210,
      filterable: false,
    },
    {
      field: 'offer_name',
      headerName: 'Offer',
      type: 'string',
      width: 210,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: 'advertiser_name',
      headerName: 'Advertiser name',
      type: 'string',
      width: 210,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          operator.value === 'contains' || operator.value === 'equals',
      ),
    },
    {
      field: 'url_name',
      headerName: 'Page name',
      width: 300,
      filterable: false,
    },
    {
      field: 'url_id',
      headerName: 'Url Id',
      filterable: false,
    },
    {
      field: 'offer_id',
      headerName: 'Offer Id',
      filterable: false,
    },
    {
      field: 'advertiser_id',
      headerName: 'Advertiser Id',
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ row }: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            sx={{
              color: 'primary.main',
            }}
            onClick={handleDeleteClick(row.userId, row.link)}
          />,
        ];
      },
    },
  ];
};

export const filterLinks = (linkList?: LinkType[], searchTerm?: string) => {
  let filtered = linkList;
  if (searchTerm && linkList) {
    filtered = linkList.filter(
      (link) =>
        link.link.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.rotator_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.offer_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.advertiser_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        link.url_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        link.url_id?.toString().includes(searchTerm.toLowerCase()) ||
        link.offer_id?.toString().includes(searchTerm.toLowerCase()) ||
        link.advertiser_id?.toString().includes(searchTerm.toLowerCase()),
    );
  }
  return filtered;
};
