/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../../types/api/Rotators";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCbaAppRotatorContents = /* GraphQL */ `mutation CreateCbaAppRotatorContents(
  $input: CreateCbaAppRotatorContentsInput!
) {
  createCbaAppRotatorContents(input: $input) {
    rotator_content_id
    rotator_id
    advertiser_id
    advertiser_name
    composite_hash
    offer_file_id
    offer_id
    offer_name
    offer_url
    offer_url_id
    preview_url
    rotator_content_custom_weight
    rotator_content_status
    rotator_content_weight
    rotator_name
    tune_client_account
    url_name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCbaAppRotatorContentsMutationVariables,
  APITypes.CreateCbaAppRotatorContentsMutation
>;
export const updateCbaAppRotatorContents = /* GraphQL */ `mutation UpdateCbaAppRotatorContents(
  $input: UpdateRotatorContentsInput!
) {
  updateCbaAppRotatorContents(input: $input) {
    rotator_content_id
    rotator_id
    advertiser_id
    advertiser_name
    composite_hash
    offer_file_id
    offer_id
    offer_name
    offer_url
    offer_url_id
    preview_url
    rotator_content_custom_weight
    rotator_content_status
    rotator_content_weight
    rotator_name
    tune_client_account
    url_name
    is_control
  }
}
` as GeneratedMutation<
  APITypes.UpdateCbaAppRotatorContentsMutationVariables,
  APITypes.UpdateCbaAppRotatorContentsMutation
>;
export const deleteCbaAppRotatorContents = /* GraphQL */ `mutation DeleteCbaAppRotatorContents(
  $input: DeleteCbaAppRotatorContentsInput!
) {
  deleteCbaAppRotatorContents(input: $input) {
    rotator_content_id
    rotator_id
    advertiser_id
    advertiser_name
    composite_hash
    offer_file_id
    offer_id
    offer_name
    offer_url
    offer_url_id
    preview_url
    rotator_content_custom_weight
    rotator_content_status
    rotator_content_weight
    rotator_name
    tune_client_account
    url_name
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCbaAppRotatorContentsMutationVariables,
  APITypes.DeleteCbaAppRotatorContentsMutation
>;
