import { ListChildComponentProps, VariableSizeList } from 'react-window';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useContext } from 'react';

export const LISTBOX_PADDING = 8; // px

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function DefaultRenderRow(props: ListChildComponentProps) {
  const outerProps = useContext<{ multiple?: boolean }>(OuterElementContext);
  const { data, index, style } = props;
  const dataSet = data[index];
  const [optionProps, option] = dataSet;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const isSelected = !option.all
    ? optionProps['aria-selected']
    : !data.some(
        (dataEntry: any) => !dataEntry[1].all && !dataEntry[0]['aria-selected'],
      );

  if (outerProps.multiple) {
    return (
      <Typography
        component="li"
        {...optionProps}
        noWrap
        style={inlineStyle}
        key={option.id}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={isSelected}
        />
        {`${option.label}`}
      </Typography>
    );
  }
  return (
    <Typography
      component="li"
      {...optionProps}
      noWrap
      style={inlineStyle}
      key={option.id}>
      {`${option.label}`}
    </Typography>
  );
}

export const OuterElementContext = React.createContext({});

export const OuterElementType = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  },
);

export function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
