import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { del, generateClient, get, post, put } from 'aws-amplify/api';
import { ListUsersResponse, CognitoUserType } from '../types/api/User';
import {
  CreateGroupPermissionsInput,
  DeleteGroupPermissionsInput,
  GroupPermission,
  GroupPermissionQueryVariables,
  UpdateGroupPermissionsInput,
} from '../types/api/GroupPermission';
import { listGroupPermissions } from '../graphql/groupPermissions/quieries';
import {
  createGroupPermissions,
  deleteGroupPermissions,
  updateGroupPermissions,
} from '../graphql/groupPermissions/mutation';
import {
  CreateTenantInput,
  DeleteTenantInput,
  GetTenantInput,
  GetTenantListInput,
  GetTenantListOutput,
  Tenant,
  UpdateTenantInput,
} from '../types/api/Tenant';

const apiName = 'AdminQueries';

const client = generateClient();

export const adminApi = createApi({
  reducerPath: 'adminApi',
  keepUnusedDataFor: 3000,
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['UserList', 'GroupPermissionList', 'TenantUserList'],
  endpoints: (builder) => {
    return {
      getUserList: builder.query<CognitoUserType[], void>({
        async queryFn() {
          let path = '/listUsers';

          try {
            let options = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${(
                  await fetchAuthSession()
                )?.tokens?.accessToken.toString()}`,
              },
            };

            const response = get({ apiName, path, options });
            const result = (await (
              await response.response
            ).body.json()) as unknown;

            return {
              data: (result as ListUsersResponse).Users || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
        providesTags: ['UserList'],
      }),
      getGroupPermissionList: builder.query<
        GroupPermission[],
        GroupPermissionQueryVariables | undefined
      >({
        async queryFn(variables) {
          try {
            const result = await client.graphql({
              query: listGroupPermissions,
              variables: variables,
            });

            return {
              data: result.data?.listGroupPermissions?.items || [],
            };
          } catch (error) {
            return { error } as any;
          }
        },
        providesTags: ['GroupPermissionList'],
      }),
      updateUserAttributes: builder.mutation<void, any>({
        async queryFn(input) {
          let path = '/updateUserAttributes';
          try {
            let options = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${(
                  await fetchAuthSession()
                )?.tokens?.accessToken.toString()}`,
              },
              body: input,
            };
            const result = post({ apiName, path, options });
            const response = await result.response;

            return {
              data: response.body || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['UserList'],
      }),
      createGroupPermission: builder.mutation<
        GroupPermission,
        CreateGroupPermissionsInput
      >({
        async queryFn(input) {
          try {
            const result = await client.graphql({
              query: createGroupPermissions,
              variables: { input },
            });
            return {
              data: result.data || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['GroupPermissionList'],
      }),
      updateGroupPermission: builder.mutation<
        GroupPermission,
        UpdateGroupPermissionsInput
      >({
        async queryFn(input) {
          try {
            const result = await client.graphql({
              query: updateGroupPermissions,
              variables: { input },
            });
            return {
              data: result.data || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['GroupPermissionList'],
      }),
      deleteGroupPermission: builder.mutation<
        GroupPermission,
        DeleteGroupPermissionsInput
      >({
        async queryFn(input) {
          try {
            const result = await client.graphql({
              query: deleteGroupPermissions,
              variables: { input },
            });
            return {
              data: result.data || null,
            };
          } catch (error) {
            return { error } as any;
          }
        },
        invalidatesTags: ['GroupPermissionList'],
      }),
      createTenantUser: builder.mutation<Tenant, CreateTenantInput>({
        async queryFn(input) {
          try {
            const restOperation = post({
              apiName: 'adminRestAPI',
              path: `/create-user/${input.userRole}`,
              options: {
                // @ts-ignore
                body: {
                  ...input.user,
                },
              },
            });

            const { body } = await restOperation.response;
            const response = await body.json();

            return {
              data: response,
            };
          } catch (error: any) {
            return { error: error } as any;
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'TenantUserList', id: arg.userRole },
        ],
      }),
      updateTenantUser: builder.mutation<Tenant, UpdateTenantInput>({
        async queryFn(input) {
          try {
            const restOperation = put({
              apiName: 'adminRestAPI',
              path: `/update-user/${input.userRole}/${input.userId}`,
              options: {
                // @ts-ignore
                body: {
                  ...input.user,
                },
              },
            });

            const { body } = await restOperation.response;
            const response = await body.json();

            return {
              data: response,
            };
          } catch (error: any) {
            return { error: error.message } as any;
          }
        },
        invalidatesTags: ['TenantUserList'],
      }),
      deleteTenantUser: builder.mutation<Tenant, DeleteTenantInput>({
        async queryFn(input) {
          try {
            const restOperation = del({
              apiName: 'adminRestAPI',
              path: `/delete-user/${input.userRole}/${input.userId}`,
            });

            const response = await restOperation.response;

            return {
              data: response,
            };
          } catch (error: any) {
            return { error: error.message } as any;
          }
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'TenantUserList', id: arg.userRole },
        ],
      }),
      getTenantUserList: builder.query<GetTenantListOutput, GetTenantListInput>(
        {
          async queryFn(input) {
            try {
              const restOperation = get({
                apiName: 'adminRestAPI',
                path: `/get-users/${input.userRole}`,
              });
              const { body } = await restOperation.response;
              const response = await body.json();

              return {
                data: response,
              };
            } catch (error: any) {
              return { error: error.message } as any;
            }
          },
          providesTags: (result, error, arg) => {
            return [{ type: 'TenantUserList', id: arg.userRole }];
          },
        },
      ),
      getTenantUser: builder.query<Tenant, GetTenantInput>({
        async queryFn(input) {
          try {
            const restOperation = get({
              apiName: 'adminRestAPI',
              path: `/get-user/${input.userRole}/${input.userId}`,
            });
            const { body } = await restOperation.response;
            const response = await body.json();

            return {
              data: response,
            };
          } catch (error: any) {
            return { error: error.message } as any;
          }
        },
      }),
    };
  },
});

export const {
  useGetUserListQuery,
  useGetGroupPermissionListQuery,
  useUpdateUserAttributesMutation,
  useCreateGroupPermissionMutation,
  useUpdateGroupPermissionMutation,
  useDeleteGroupPermissionMutation,
  useCreateTenantUserMutation,
  useGetTenantUserListQuery,
  useDeleteTenantUserMutation,
  useUpdateTenantUserMutation,
  useGetTenantUserQuery,
} = adminApi;
