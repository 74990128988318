import { Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { RouterPath } from '../../../utils/routerUtils';
import { dictionary } from '../../../utils/dictionary';
import React from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export const dashboardBreadcrumbLink = (
  <Link component={NavLink} to={RouterPath.MAIN}>
    Dashboard
  </Link>
);

export const adminBreadcrumbLink = (
  <Link component={NavLink} to={RouterPath.ADMIN}>
    Admin
  </Link>
);

const replaceParamsToRegexpString = (url: string) =>
  url.replaceAll(/:\w+/g, '.+');

const createRegexpFromRoute = (route: string) =>
  RegExp(`^${replaceParamsToRegexpString(route)}$`);

const testUrl = (path: string, url: string) =>
  createRegexpFromRoute(path).test(url);

export const getBreadcrumbs = (url: string): ReactJSXElement | null => {
  const urlWithoutParams = url.split('?')[0];

  if (testUrl(RouterPath.ADMIN, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        <Typography>{dictionary.admin.pageHeader}</Typography>
      </>
    );
  } else if (testUrl(RouterPath.CREATE_TENANT, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        {adminBreadcrumbLink}
        <Typography>{dictionary.admin.createTenantPageHeader}</Typography>
      </>
    );
  } else if (testUrl(RouterPath.UPDATE_TENANT, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        {adminBreadcrumbLink}
        <Typography>{dictionary.admin.updateTenantPageHeader}</Typography>
      </>
    );
  } else if (testUrl(RouterPath.CREATE_ADMIN, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        {adminBreadcrumbLink}
        <Typography>{dictionary.admin.createAdminPageHeader}</Typography>
      </>
    );
  } else if (testUrl(RouterPath.UPDATE_ADMIN, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        {adminBreadcrumbLink}
        <Typography>{dictionary.admin.updateAdminPageHeader}</Typography>
      </>
    );
  } else if (testUrl(RouterPath.CREATE_TENANT_USER, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        {adminBreadcrumbLink}
        <Typography>{dictionary.admin.createTenantUserPageHeader}</Typography>
      </>
    );
  } else if (testUrl(RouterPath.UPDATE_TENANT_USER, urlWithoutParams)) {
    return (
      <>
        {dashboardBreadcrumbLink}
        {adminBreadcrumbLink}
        <Typography>{dictionary.admin.updateTenantUserPageHeader}</Typography>
      </>
    );
  }
  return null;
};
