import { Box, Button, Paper } from "@mui/material";
import PageSubHeader from "../../common/PageSubHeader/PageSubHeader";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import ConfirmationDialog from "../../common/ConfirmationDialog/ConfirmationDialog";
import * as React from "react";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { dictionary } from "../../../utils/dictionary";
import { getAdminListColumns } from "./utils";
import {
  useDeleteTenantUserMutation,
  useGetTenantUserListQuery,
} from "../../../store/admin";
import { TYPE_USER_ROLES, USER_ROLES } from "../../../utils/auth";

interface Props {
  userRole: TYPE_USER_ROLES;
  pathToCreate?: string;
  pathToUpdate?: string;
  pageSubHeader: string;
  createButtonLabel?: string;
  currentUserRole: TYPE_USER_ROLES;
}

export default function UserList({
  userRole,
  pathToCreate,
  pathToUpdate,
  pageSubHeader,
  createButtonLabel,
  currentUserRole,
}: Props) {
  const [deleteUser, { isLoading: deleteLoading }] =
    useDeleteTenantUserMutation();

  const { confirmationDialogProps, openDialog } = useConfirmDialog({
    text: dictionary.admin.deleteTenantConfirmText,
  });

  const { data: admins, isLoading } = useGetTenantUserListQuery({
    userRole: userRole,
  });

  const updateIsDisabled =
    userRole === USER_ROLES.SYSTEM_ADMIN &&
    currentUserRole !== USER_ROLES.SUPER_ADMIN;

  const handleDelete = (id: string) => () => {
    openDialog(() => {
      deleteUser({
        userId: id,
        userRole: userRole,
      });
    });
  };

  return (
    <Paper sx={{ p: 4, mt: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <PageSubHeader text={pageSubHeader} />
        {pathToCreate && (
          <Button component={Link} to={pathToCreate} sx={{ paddingTop: "4px" }}>
            <AddIcon /> {createButtonLabel}
          </Button>
        )}
      </Box>
      <DataGridPremium
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          pinnedColumns: {
            right: ["actions"],
          },
          columns: {
            columnVisibilityModel: {
              actions: !updateIsDisabled,
            },
          },
        }}
        pagination
        pageSizeOptions={[5, 10, 15]}
        loading={isLoading || deleteLoading}
        columns={getAdminListColumns(
          !updateIsDisabled ? handleDelete : undefined,
          pathToUpdate,
          updateIsDisabled
        )}
        rows={admins?.Items || []}
        disableRowSelectionOnClick
        sx={{
          minHeight: "100px",
          "& .MuiDataGrid-virtualScroller": {
            minHeight: "100px",
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            paddingRight: "0 !important",
          },
        }}
        getRowId={(row: any) => row?.userId}
      />
      <ConfirmationDialog {...confirmationDialogProps} />
    </Paper>
  );
}
