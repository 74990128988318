import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  url: string;
}

export default function TableEditLinkActionCell({ url }: Props) {
  const navigate = useNavigate();

  return (
    <GridActionsCellItem
      icon={<EditIcon />}
      label="Edit"
      sx={{
        color: 'primary.main',
      }}
      onClick={() => {
        navigate(url);
      }}
    />
  );
}
