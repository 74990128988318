import styled from "@emotion/styled";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import React from "react";

export const TableErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));
