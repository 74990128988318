import { Box, CircularProgress } from '@mui/material';
import CustomVerticalStepper from '../../common/CustomVerticalStepper/CustomVerticalStepper';
import TrafficStep from '../TrafficStep/TrafficStep';
import PageStep from '../PageStep/PageStep';
import { dictionary } from '../../../utils/dictionary';
import PageHeader from '../../common/PageHeader/PageHeader';
import ParametersStep from '../ParametersStep/ParametersStep';
import { Formik } from 'formik';
import ConfirmationStep from '../ConfirmationStep/ConfirmationStep';
import BackButton from '../../common/BackButton/BackButton';
import { RotatorLinkSchema } from '../../../utils/validation/RotatorLinkSchema';
import { useCreateLinkMutation } from '../../../store/links';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik/dist/types';
import { RouterPath } from '../../../utils/routerUtils';
import AdvertiserStep from '../AdvertiserStep/AdvertiserStep';

const steps = [
  {
    label: dictionary.links.newLink.advertiserStep,
    content: <AdvertiserStep />,
  },
  {
    label: dictionary.links.newLink.trafficStep,
    content: <TrafficStep />,
  },
  {
    label: dictionary.links.newLink.pageStep,
    content: <PageStep />,
  },
  {
    label: dictionary.links.newLink.parametersStep,
    content: <ParametersStep />,
  },
  {
    label: dictionary.links.newLink.confirmationStep,
    content: <ConfirmationStep />,
  },
];

export default function NewLinkContainer() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [createLink, { isLoading }] = useCreateLinkMutation();

  const handleSubmit = async (
    values: Partial<RotatorLinkForm>,
    actions: FormikHelpers<Partial<RotatorLinkForm>>,
  ) => {
    const userAttributes = await fetchUserAttributes();
    createLink({
      user_id: userAttributes.sub!,
      link: values.generatedLink!,
      advertiser_id: values.advertiser?.advertiser_id,
      advertiser_name: values.advertiser?.advertiser_name,
      offer_id: values.pageDestination?.offer_id,
      offer_name: values.pageDestination?.offer_name,
      rotator_id: values.pageDestination?.rotator_id,
      rotator_name: values.pageDestination?.rotator_name,
      url_id: values.pageDestination?.url_id,
      url_name: values.pageDestination?.url_name,
    }).then((response: any) => {
      if (response.error) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(dictionary.links.newLink.success, {
          variant: 'success',
        });
      }

      navigate(RouterPath.LINKS);
      actions.resetForm();
    });
  };

  return (
    <Box height={'calc(100vh - 266px)'} pb={4}>
      {isLoading && (
        <CircularProgress
          sx={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )}
      <Box sx={{ mb: 2 }}>
        <BackButton />
      </Box>
      <PageHeader text={dictionary.links.newLink.header} />
      <Formik
        validateOnMount
        initialValues={{ step: 0 }}
        onSubmit={handleSubmit}
        validationSchema={RotatorLinkSchema}>
        {({ handleSubmit, isValid, setFieldValue }) => (
          <CustomVerticalStepper
            steps={steps}
            width="96%"
            onFinish={handleSubmit}
            disabledNext={!isValid || isLoading}
            onNextStep={(step) => setFieldValue('step', step)}
          />
        )}
      </Formik>
    </Box>
  );
}
