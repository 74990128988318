import { GridToolbar } from '@mui/x-data-grid-premium';
import { Box, Button } from '@mui/material';
import { GridToolbarProps } from '@mui/x-data-grid/components/toolbar/GridToolbar';

export default function CustomGridToolbar(props: GridToolbarProps) {
  const { onSaveView } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      p={1.5}>
      <GridToolbar {...props} />
      <Box>
        {props.isViewChanged && (
          <Button sx={{ paddingTop: '4px' }} onClick={onSaveView}>
            SAVE VIEW
          </Button>
        )}
      </Box>
    </Box>
  );
}
