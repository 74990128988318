import { Typography } from '@mui/material';

interface Props {
  text: string;
}

export default function PageSubHeader({ text }: Props) {
  return (
    <Typography variant="h4" mb={3}>
      {text}
    </Typography>
  );
}
