import { Outlet, Navigate } from 'react-router-dom';
import { detectIsAdmin } from '../utils/auth';
import React, { useEffect, useState } from 'react';

export const ProtectedAdminRoute = () => {
  const [isAdmin, setAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    async function setIsAdmin() {
      const isAdmin = await detectIsAdmin();
      setAdmin(isAdmin);
    }

    setIsAdmin();
  }, []);

  if (isAdmin === null) {
    return null;
  }

  return isAdmin ? <Outlet /> : <Navigate to="/" />;
};
