import * as APITypes from '../../types/api/Advertiser';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCbaAdvertisers = /* GraphQL */ `query ListCbaAdvertisers {
  listCbaAdvertisers {
    items {
         advertiser_id
         advertiser_name
    }
  }
}
` as GeneratedQuery<void, APITypes.ListAdvertiserQuery>;
