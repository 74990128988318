import { ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { LISTBOX_PADDING } from '../../common/AutocompleteListboxComponent/utils';
import { Box } from '@mui/material';

export function renderTrafficRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  const dataSet = data[index];

  const [optionProps, option] = dataSet;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography
      component="li"
      {...optionProps}
      noWrap
      style={inlineStyle}
      key={option.id}>
      <Box display="flex" gap={4} width="70%" justifyContent="space-between">
        <Box flex={2}>
          <Typography fontWeight="500">{`${option.label}`}</Typography>
        </Box>
        <Box flex={2}>{`${option.value.advertiser_name}`}</Box>
        <Box flex={1}>{`${option.value.channel}`}</Box>
      </Box>
    </Typography>
  );
}
