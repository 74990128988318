import React, { useState } from 'react';
import { Button, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Close as CloseIcon } from '@mui/icons-material';
import { ProfileAttributes } from '../components/Profile/ProfileAttributes';
import { ProfileVerifyEmail } from '../components/Profile/ProfileVerifyEmail';
import { ProfilePasswordReset } from '../components/Profile/ProfilePasswordReset';
import { getCurrentUser, resetPassword } from 'aws-amplify/auth';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export const Profile = () => {
  const [open, setSnackBarOpen] = React.useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [snackBarMsg, setSnackBarMsg] = React.useState('');
  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const handleVerifyToken = () => {
    setDialogOpen(true);
  };

  const handleResetPassword = async () => {
    const { username } = await getCurrentUser();

    await resetPassword({
      username,
    });
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={`${snackBarMsg}`}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <ProfileVerifyEmail
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        setSnackBarOpen={setSnackBarOpen}
        setSnackBarMsg={setSnackBarMsg}
      />
      <ProfilePasswordReset
        dialogOpen={passwordDialogOpen}
        setDialogOpen={setPasswordDialogOpen}
        setSnackBarOpen={setSnackBarOpen}
        setSnackBarMsg={setSnackBarMsg}
      />
      <Wrapper>
        <ProfileAttributes
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarOpen={setSnackBarOpen}
        />
      </Wrapper>
      <Wrapper>
        <Typography>Need to verify your email?</Typography>

        <Button
          variant={'contained'}
          size={'large'}
          color="primary"
          onClick={handleVerifyToken}>
          Validate Email
        </Button>
      </Wrapper>

      <Wrapper>
        <Typography>Change your password</Typography>

        <Button
          variant={'contained'}
          size={'large'}
          color="primary"
          onClick={handleResetPassword}>
          Change Password
        </Button>
      </Wrapper>
    </React.Fragment>
  );
};
