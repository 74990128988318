import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { CssBaseline } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  AppContent,
  Drawer,
  drawerTransition,
  drawerWidth,
  MainContent,
  Root,
} from './utils';
import { SidebarItemsType, SideBarNavItems } from '../../types/sidebar';
import { detectIsAdmin } from '../../utils/auth';
import dashboardItems from '../../components/sidebar/dashboardItems';
import GlobalStyle from '../../components/GlobalStyle';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { Breadcrumbs } from '../../components/common/Breadcrumbs/Breadcrumbs';
import Footer from '../../components/Footer';

interface Props {
  children?: React.ReactNode;
}

const Dashboard: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  const [sideMenuOpen, setSideMenuOpen] = useState(!isMobile);
  const [sideBarItems, setSideBarItems] = useState<SideBarNavItems>([]);
  const handleDrawerToggle = () => {
    setSideMenuOpen(!sideMenuOpen);
  };

  useEffect(() => {
    async function setDashboardItems() {
      const isAdmin = await detectIsAdmin();
      if (!isAdmin) {
        const copy = [...dashboardItems];
        const copyPages = [...copy[0].pages];
        const filteredPages = copyPages.filter(
          (item: SidebarItemsType) => !item.isAdminProtected && item,
        );
        setSideBarItems([
          { ...copy[0], pages: filteredPages },
          dashboardItems[1],
        ]);
      } else {
        setSideBarItems(dashboardItems);
      }
    }

    setDashboardItems();
  }, []);
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer open={sideMenuOpen}>
        <Sidebar
          PaperProps={{
            style: {
              width: isMobile ? '80%' : sideMenuOpen ? drawerWidth : 0,
              transition: drawerTransition,
            },
          }}
          items={sideBarItems}
          open={sideMenuOpen}
          onClose={handleDrawerToggle}
          variant={isMobile ? 'temporary' : 'permanent'}
        />
      </Drawer>
      <AppContent isDrawerOpen={sideMenuOpen} isMobile={isMobile}>
        <Navbar
          onDrawerToggle={handleDrawerToggle}
          isDrawerOpen={sideMenuOpen}
        />
        <MainContent p={isLgUp ? 12 : 5} pt={5}>
          <Breadcrumbs />
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
