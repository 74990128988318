import * as APITypes from '../../types/api/LinkTrackingTemplates';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listLinkTrackingTemplates =
  /* GraphQL */ `query ListLinkTrackingTemplates {
  listLinkTrackingTemplates {
    items {
      channel
      tracking_template
    }
  }
}
` as GeneratedQuery<
    APITypes.LinkTrackingTemplatesQueryVariables,
    APITypes.ListCbaAppRotatorContentsQuery
  >;
