import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridFilterModel,
  GRID_AGGREGATION_FUNCTIONS,
} from '@mui/x-data-grid-premium';
import { FC, useMemo } from 'react';
import { RotatorsSubTable } from '../RotatorsSubTable/RotatorsSubTable';
import CustomGridToolbar from '../CustomGridToolbar/CustomGridToolbar';
import {
  customAllStatusAggregationFunc,
  getColumns,
  getInitialState,
  TABLE_VIEW_STORAGE_KEY,
} from './utils';
import { useSaveTableView } from '../../../hooks/useSaveTableView';
import { Typography } from '@mui/material';

interface Props {
  rows: Array<object>;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  loading?: boolean;
}

export const RotatorsTable: FC<Props> = ({
  rows,
  onFilterModelChange,
  loading,
}) => {
  const apiRef = useGridApiRef();

  const { isViewChanged, handleViewChange, handleSaveView } = useSaveTableView({
    apiRef,
    storageKey: TABLE_VIEW_STORAGE_KEY,
  });

  const rotatorsColumns = useMemo(() => getColumns(), []);

  const handledInitialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: getInitialState(),
  });

  return (
    <>
      <Typography variant="h3" mb={3}>
        Rotators
      </Typography>
      <Box
        sx={{
          height: 'calc(100vh - 266px)',
          minHeight: '240px',
          width: '100%',
        }}>
        <DataGridPremium
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            statusAggr: customAllStatusAggregationFunc,
          }}
          onColumnVisibilityModelChange={handleViewChange}
          onColumnResize={handleViewChange}
          onColumnOrderChange={handleViewChange}
          onSortModelChange={handleViewChange}
          loading={loading}
          rows={rows}
          columns={rotatorsColumns}
          apiRef={apiRef}
          initialState={handledInitialState}
          getRowId={(row: any) => row?.rotator_content_id}
          groupingColDef={{
            filterable: true,
            hideDescendantCount: true,
            headerName: 'Rotator id',
            hideable: false,
          }}
          filterMode="server"
          onFilterModelChange={onFilterModelChange}
          slots={{ toolbar: CustomGridToolbar, row: RotatorsSubTable }}
          slotProps={{
            row: { rows: rows },
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 100 },
              filterProps: { debounceMs: 100 },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
              excelOptions: { disableToolbarButton: true },
              isViewChanged: isViewChanged,
              onSaveView: handleSaveView,
              showNewLinkButton: true,
            },
            panel: {
              sx: {
                top: '-200px !important',
              },
            },
          }}
          pagination
          pageSizeOptions={[10, 15, 25]}
          getAggregationPosition={() => 'inline'}
          disableDensitySelector
          disableVirtualization
          sx={{
            '& .MuiDataGrid-virtualScrollerRenderZone': {
              width: '100%',
            },
            '& .MuiDataGrid-toolbarContainer': {
              justifyContent: 'flex-start',
              gap: 2,
              '& .MuiBox-root': {
                flex: 0,
              },
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
          }}
        />
      </Box>
    </>
  );
};
