/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../../types/api/Rotators';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCbaAppRotatorContents =
  /* GraphQL */ `query GetCbaAppRotatorContents($rotator_id: Int!, $rotator_content_id: Int!) {
  getCbaAppRotatorContents(
    rotator_id: $rotator_id
    rotator_content_id: $rotator_content_id
  ) {
    rotator_content_id
    rotator_id
    advertiser_id
    advertiser_name
    composite_hash
    offer_file_id
    offer_id
    offer_name
    offer_url
    offer_url_id
    preview_url
    rotator_content_custom_weight
    rotator_content_status
    rotator_content_weight
    rotator_name
    tune_client_account
    url_name
    total_clicks
    is_control
    client_name
    distinct_days_with_data
    first_seen_local
    last_modified
    last_seen_local
    total_conversions
    total_days_live
    total_revenue
  }
}
` as GeneratedQuery<
    APITypes.GetCbaAppRotatorContentsQueryVariables,
    APITypes.GetCbaAppRotatorContentsQuery
  >;
export const listCbaAppRotatorContents =
  /* GraphQL */ `query ListCbaAppRotatorContents {
  listCbaAppRotatorContents {
    items {
      rotator_content_id
      rotator_id
      advertiser_id
      advertiser_name
      composite_hash
      offer_file_id
      offer_id
      offer_name
      offer_url
      offer_url_id
      preview_url
      rotator_content_custom_weight
      rotator_content_status
      rotator_content_weight
      rotator_name
      tune_client_account
      url_name
      total_clicks
      is_control
      client_name
      distinct_days_with_data
      first_seen_local
      last_modified
      last_seen_local
      total_conversions
      total_days_live
      total_revenue
    }
  }
}
` as GeneratedQuery<
    APITypes.ListCbaAppRotatorContentsQueryVariables,
    APITypes.ListCbaAppRotatorContentsQuery
  >;
