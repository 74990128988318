import { TYPE_USER_ROLES } from '../../utils/auth';

export interface Tenant {
  userId: string;
  tenantId: string;
  sortKey: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userRole: TYPE_USER_ROLES;
  createdBById: string;
  createdByName: string;
  createdByUserRole: TYPE_USER_ROLES;
  createdAt: string;
  adminAccess: AccessItem[];
  readAccess: AccessItem[];
  writeAccess: AccessItem[];
}

export type AccessType = 'adminAccess' | 'readAccess' | 'writeAccess';

interface AccessItem {
  advertiserId: number;
  advertiserName: string;
  offerAccess: {
    isRestricted: boolean;
    accessibleIds: number[] | null;
  };
  trafficSourceAccess: {
    isRestricted: boolean;
    accessibleIds: number[] | null;
  };
}

export interface TenantForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  adminAccess: AccessItem[];
  readAccess: AccessItem[];
  writeAccess: AccessItem[];
  step?: number;
  userRole?: TYPE_USER_ROLES;
  tenantId?: string;
}

export interface CreateTenantInput {
  user: TenantForm;
  userRole: TYPE_USER_ROLES;
}

export interface DeleteTenantInput {
  userId: string;
  userRole: TYPE_USER_ROLES;
}

export interface UpdateTenantInput {
  userId: string;
  user: TenantForm;
  userRole: TYPE_USER_ROLES;
}

export interface GetTenantInput {
  userId: string;
  userRole: TYPE_USER_ROLES;
}

export interface GetTenantListInput {
  userRole: TYPE_USER_ROLES;
}

export interface GetTenantListOutput {
  Items: Tenant[];
}

export const defaultAccessItem = {
  advertiserId: '',
  advertiserName: '',
  offerAccess: {
    isRestricted: false,
    accessibleIds: [],
  },
  trafficSourceAccess: {
    isRestricted: false,
    accessibleIds: [],
  },
};

export const DEFAULT_TENANT_FORM: TenantForm = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  adminAccess: [],
  readAccess: [],
  writeAccess: [],
};
