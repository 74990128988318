import {
  GridActionsCellItem,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import TableEditLinkActionCell from '../../common/TableEditLinkActionCell/TableEditLinkActionCell';
import { USER_ROLES } from '../../../utils/auth';

export const getAdminListColumns = (
  handleDeleteClick?: (id: string) => () => void,
  pathToUpdate?: string,
  updateIsDisabled?: boolean,
) => {
  return [
    {
      field: 'firstName',
      headerName: 'First name',
      filterable: false,
      width: 200,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      filterable: false,
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      filterable: false,
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      filterable: false,
      width: 200,
    },
    {
      field: 'userRole',
      headerName: 'Role',
      filterable: false,
      width: 200,
    },
    {
      field: 'createdByName',
      headerName: 'Created by',
      filterable: false,
      width: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      filterable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? new Date(params.value)?.toLocaleString() : '',
      width: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: ({ id, row: { sortKey, userRole } }: GridRowParams) => {
        if (updateIsDisabled) {
          return [];
        }

        const userKey = userRole === USER_ROLES.TENANT_USER ? id : sortKey;
        return [
          <>
            {pathToUpdate && (
              <TableEditLinkActionCell
                url={pathToUpdate.replace(':user_id', id as string)}
              />
            )}
          </>,
          <>
            {handleDeleteClick && (
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleDeleteClick(userKey as string)}
              />
            )}
          </>,
        ];
      },
    },
  ];
};
