import { CbaAppRotatorContents } from '../../../types/api/Rotators';
import { AutocompleteOption } from '../../common/VirtualizedAutocomplete/VirtualizedAutocomplete';
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridRenderEditCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { Link, Radio } from '@mui/material';
import React from 'react';
import { percentageFormatting } from '../../Rotators/RotatorsSubTable/utils';
import { GridColDef } from '@mui/x-data-grid';
import {
  getGridNumericOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium';

export const mapPageAutocompleteOptions = (
  rotators: Array<CbaAppRotatorContents>,
): Array<AutocompleteOption> => {
  return rotators?.map((rotator) => ({
    label: rotator.url_name,
    value: rotator,
    id: rotator.rotator_id,
  }));
};

export const pageColumns: Array<GridColDef> = [
  {
    field: GRID_CHECKBOX_SELECTION_COL_DEF.field,
    width: 38,
    filterable: false,
    renderCell: (cellValues) => {
      return (
        <Radio
          checked={cellValues.value}
          onChange={(val) =>
            cellValues.api.setRowSelectionModel([cellValues.id])
          }
          value={cellValues.id}
          name="radio-buttons"
        />
      );
    },
  },
  {
    field: 'url_id',
    headerName: 'Url Id',
    width: 80,
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === '=' ||
        operator.value === '>' ||
        operator.value === '<',
    ),
  },
  {
    field: 'url_type',
    headerName: 'Url type',
    width: 120,
    type: 'singleSelect',
    valueOptions: [
      {
        value: 'Page in Rotator',
        label: 'Page in Rotator',
      },
      {
        value: 'Direct to Page',
        label: 'Direct to Page',
      },
    ],
    filterOperators: getGridSingleSelectOperators().filter(
      (operator) => operator.value === 'is',
    ),
  },
  {
    field: 'url_nickname',
    headerName: 'Url nickname',
    filterable: false,
    type: 'string',
    width: 360,
    renderCell: (params: GridRenderEditCellParams) => {
      return (
        <Link target="blank" href={params.row.preview_url}>
          {params.value}
        </Link>
      );
    },
  },
  {
    field: 'rotator_id',
    headerName: 'Rotator Id',
    filterable: false,
    width: 72,
  },
  {
    field: 'rotator_name',
    headerName: 'Rotator name',
    width: 160,
    filterable: false,
  },
  {
    field: 'rotator_content_weight',
    headerName: 'Weight',
    width: 100,
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value ? percentageFormatting(params.value) : undefined;
    },
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    filterable: false,
  },
  {
    field: 'offer_name',
    headerName: 'Offer',
    type: 'string',
    width: 210,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === 'contains' || operator.value === 'equals',
    ),
  },
  {
    field: 'rotator_content_id',
    headerName: 'Rotator content Id',
    filterable: false,
  },
  {
    field: 'rotator_content_status',
    headerName: 'Status',
    filterable: false,
  },
  {
    field: 'url_status',
    headerName: 'Page status',
    filterable: false,
    type: 'string',
  },
  {
    field: 'url_name',
    headerName: 'Page name',
    width: 300,
    filterable: false,
  },
  {
    field: 'preview_url',
    headerName: 'Preview url',
    type: 'string',
    width: 180,
  },
  {
    field: 'offer_url',
    headerName: 'Offer url',
    type: 'string',
    width: 180,
  },
  {
    field: 'tracking_url_base',
    headerName: 'Tracking url base',
    filterable: false,
    type: 'string',
    width: 260,
  },
  {
    field: 'tracking_url_hostname',
    headerName: 'Tracking url hostname',
    filterable: false,
    type: 'string',
    width: 160,
  },
  {
    field: 'offer_id',
    headerName: 'Offer Id',
    filterable: false,
  },
  {
    field: 'advertiser_id',
    headerName: 'Advertiser Id',
    filterable: false,
  },
  {
    field: 'client_name',
    headerName: 'Client_name',
    filterable: false,
    type: 'string',
    width: 120,
  },
];

export const pegeDestinationsTableInitialState = {
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
  },
  columns: {
    columnVisibilityModel: {
      rotator_content_id: false,
      client_name: false,
      advertiser_id: false,
      offer_id: false,
      tracking_url_hostname: false,
      tracking_url_base: false,
      url_name: false,
      url_status: false,
      rotator_content_status: false,
    },
  },
};
