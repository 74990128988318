import {
  useGetAdvertisersListQuery,
  useGetAffiliatesListQuery,
  useGetOffersListQuery,
} from '../../../store/links';
import { AccessType, TenantForm } from '../../../types/api/Tenant';
import { USER_ROLES } from '../../../utils/auth';
import { useTenantAdmin } from '../../../hooks/useTenantAdmin';

interface Props {
  tenantForm: TenantForm;
  accessType: AccessType;
  index: number;
}

export const useAdvertiserRestrictionData = ({
  tenantForm,
  accessType,
  index,
}: Props) => {
  const isTenantUser = tenantForm.userRole === USER_ROLES.TENANT_USER;

  const { data: allAdvertisers, isLoading: isLoadingAdvertisers } =
    useGetAdvertisersListQuery(undefined, { skip: isTenantUser });

  const selectedAdvertiserId = tenantForm[accessType][index]?.advertiserId;

  const { data: allTrafficSources, isLoading: isLoadingTrafficSources } =
    useGetAffiliatesListQuery({
      filter: {
        items: [
          {
            field: 'advertiser_id',
            operator: '=',
            value: `${selectedAdvertiserId}`,
          },
        ],
      },
    });

  const { data: allOffers, isLoading: isLoadingOffers } = useGetOffersListQuery(
    {
      filter: {
        items: [
          {
            field: 'advertiser_id',
            operator: '=',
            value: `${selectedAdvertiserId}`,
          },
        ],
      },
    },
  );

  const { tenantAdmin } = useTenantAdmin({ skip: !isTenantUser });

  const getTrafficSources = () => {
    if (!isTenantUser) {
      return allTrafficSources;
    }

    if (!tenantAdmin) {
      return [];
    }

    if (
      !tenantAdmin ||
      !tenantAdmin[accessType] ||
      tenantAdmin[accessType].length === 0
    ) {
      return [];
    }

    if (tenantAdmin[accessType][index].trafficSourceAccess.isRestricted === false) {
      return allTrafficSources;
    }

    return allTrafficSources?.filter((ts) =>
      tenantAdmin[accessType][
        index
      ].trafficSourceAccess.accessibleIds?.includes(ts.affiliate_id),
    );
  };

  const getOffers = () => {
    if (!isTenantUser) {
      return allOffers;
    }

    if (!tenantAdmin) {
      return [];
    }

    if (
      !tenantAdmin ||
      !tenantAdmin[accessType] ||
      tenantAdmin[accessType].length === 0
    ) {
      return [];
    }

    if (tenantAdmin[accessType][index].offerAccess?.isRestricted === false) {
      return allOffers;
    }

    return allOffers?.filter((of) =>
      tenantAdmin[accessType][index].offerAccess?.accessibleIds?.includes(
        of.offer_id,
      ),
    );
  };

  const getAdvertisers = () => {
    if (!isTenantUser) {
      const allSelectedAdvertisers = tenantForm[accessType].map(
        (accessItem, itemIndex) =>
          index === itemIndex ? undefined : accessItem.advertiserId,
      );

      return allAdvertisers?.filter(
        (adv) => !allSelectedAdvertisers.includes(adv.advertiser_id),
      );
    }

    if (
      !tenantAdmin ||
      !tenantAdmin[accessType] ||
      tenantAdmin[accessType].length === 0
    ) {
      return [];
    }

    return [
      {
        advertiser_id: tenantAdmin[accessType][index].advertiserId,
        advertiser_name: tenantAdmin[accessType][index].advertiserName,
      },
    ];
  };

  const advertisers = getAdvertisers();

  const trafficSources = getTrafficSources();

  const offers = getOffers();

  const isLoading =
    isLoadingAdvertisers || isLoadingTrafficSources || isLoadingOffers;

  return {
    advertisers,
    trafficSources,
    offers,
    isLoading,
  };
};
