import { useFormikContext } from 'formik';
import { RotatorLinkForm } from '../../../types/RotatorLinkForm';
import { Box } from '@mui/material';
import { dictionary } from '../../../utils/dictionary';
import PageSubHeader from '../../common/PageSubHeader/PageSubHeader';
import { useEffect } from 'react';
import LinkItem from '../../common/LinkItem/LinkItem';
import { generateLink } from './utils';
import { useTrackingTemplate } from '../ParametersStep/utils';

export default function ConfirmationStep() {
  const { values, setFieldValue } = useFormikContext<RotatorLinkForm>();

  const [trackingTemplate] = useTrackingTemplate();

  const generatedLink = generateLink(values, trackingTemplate);

  useEffect(() => {
    setFieldValue('generatedLink', generatedLink);
  }, [generatedLink, setFieldValue]);

  return (
    <Box sx={{ lineBreak: 'anywhere' }}>
      <PageSubHeader text={dictionary.links.newLink.confirmationStepHeader} />
      <Box display="flex" gap={2}>
        <LinkItem link={generatedLink} />
      </Box>
    </Box>
  );
}
